.filter-accordion-div {
  position: relative;
  background-color: transparent;
  border-bottom: 1px solid rgba(9, 49, 72, 0.19);
  padding: 0 0.5rem;
  // min-height: 100px;
  display: none;
  min-height: 13.5vh;

  @include respond(tab-port) {
    display: block;
    min-height: 10vh;
  }

  &:last-child {
    border-bottom: 1px solid $color-white;
  }
  .accordion {
    background-color: transparent;
    border-bottom: 1px solid rgba(9, 49, 72, 0.5);
    color: $color-dark-grey;
    cursor: pointer;
    padding: 0.2rem;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    font-size: 3rem;
    // margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 23.4vh;

    @include respond(tab-port) {
      height: 10vh;
    }

    .service-name {
      padding-left: 1rem;
      // padding-top: 1rem;
      font-size: 2.5rem;
      font-weight: 500;
      flex: 1;
    }
  }

  .active,
  .accordion:hover,
  .accordion:focus {
    outline: none;
  }

  .accordion:after {
    content: "";
    position: absolute;
    height: 12px;
    width: 20px;
    background-size: cover;
    top: 2rem;
    right: 25px;
    background-image: url("../../img/news/back.png");

    @include respond(phone) {
      top: 4vh;
      font-size: 3rem;
    }
  }

  .active:after {
    transform: rotateX(180deg);
  }

  .panel {
    font-size: 1.5rem;
    font-weight: 200;
    padding: 0rem 1rem;
    background-color: transparent;
    color: $color-marine;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    margin-bottom: 10px;
    border: none;
    .date-holder {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      .from-holder {
        width: 53%;
      }
      .to-holder {
        width: 53%;
      }
    }
  }
}

.filters-lg {
  display: block;
  @include respond(tab-port) {
    display: none;
  }
}
