.home {
  .section-video {
    position: absolute;
    z-index: 20;
    width: 100%;
    margin-top: -10vh;
    display: flex;
    justify-content: flex-end;

    @include respond(phone) {
      margin-top: -4vh;
    }
  }

  .section-about {
    position: relative;
    overflow: hidden;
    // overflow-y: visible;
    background-color: $color-marine;
    padding: 5rem 0 15rem 0;

    @include overlay-mesh-grid() {
      height: 200%;
      width: 135%;
      background-size: contain;
    }

    &-content {
      position: relative;
      z-index: 10;
    }

    @include respond(tab-port) {
      padding: 5rem 0 10rem 0;
    }

    .aboutus {
      visibility: hidden;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: -15vh;
    }

    &_story {
      padding: 0 8rem;

      @include respond(phone) {
        padding: 0 2.5rem;
        margin-left: 18px;
      }

      .services {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        float: right;

        h3 {
          color: $color-white;
          writing-mode: tb-rl;
          transform: rotate(-180deg);
          font-weight: 300;
          font-size: 1.2vw;

          @include respond(phone) {
            margin-right: 20px;
            font-weight: 400;
            font-size: 1.2rem;
          }
        }
        p {
          margin-bottom: 3rem;
          @include respond(phone) {
            font-weight: 400;
          }
        }

        .active {
          background-color: transparent;
        }

        .collapsible-services {
          display: inline-block;
          position: relative;
          width: -webkit-fill-available;
          padding: 0 0 0 4.5rem;

          // direction: rtl;
          @include respond(tab-port) {
            padding: 0 1rem;
          }
        }
      }
    }
  }

  .section-clients {
    position: relative;
    padding-bottom: 10rem;
    margin-top: 0rem;

    @include section-header;

    &-container {
      width: 98%;
      margin-right: 0;
      margin-left: auto;
    }

    .container-padding {
      padding: 1rem 5rem;
      // height: 45rem;

      @include respond(tab-port) {
        padding: 1rem 2rem;
      }

      @include respond(phone) {
        // height: 45rem;
      }

      .client__logo {
        width: 100%;
        margin-right: 2rem;

        @include respond(tab-port) {
          // height: 4rem;
          margin-right: 0.5rem;
        }
      }
    }
  }

  .section-projects {
    position: relative;
    margin-top: 0rem;

    @include section-header;

    .row {
      padding: 2rem 4rem 2rem 7rem;
      flex-direction: row-reverse;
      margin-top: -5rem;

      .project-card {
        // .svg {
        //   right: 0;
        //   position: relative;

        //   svg {
        //     width: 60px;
        //     height: 60px;

        //     circle {
        //       cx: 30px;
        //       cy: 30px;
        //       r: 25px;
        //     }

        //     @include respond(phone) {
        //       width: 50px;
        //       height: 50px;

        //       circle {
        //         cx: 24px;
        //         cy: 26px;
        //         r: 20px;
        //       }
        //     }
        //   }

        //   .circle-button {
        //     @include circleButton(50px);

        //     font-size: 1.5rem;
        //     background-image: url("../../img/oval/oval@2x.png"),
        //       url("../../img/back_white/back@2x.png");
        //     background-repeat: no-repeat;
        //     background-position: center;
        //     background-size: 50px 50px, 10px;
        //     position: absolute;
        //     bottom: 0.5rem;
        //     left: 0.5rem;
        //     transition: background-color 1s linear;

        //     &:hover {
        //       line-height: 59px;
        //       border: 0px solid transparent;
        //       border-radius: 50%;
        //       // border-style: solid;
        //       background-color: rgba(255, 255, 255, 0.2);
        //     }

        //     @include respond(phone) {
        //       @include circleButton(40px);
        //       // background-size: 35px 35px, 10px;
        //       background-size: 40px 40px, 10px;
        //       font-size: 6px;
        //       line-height: 59px;
        //       text-align: center;

        //       &:hover {
        //         line-height: normal;
        //       }
        //     }
        //   }
        // }
        .project-div {
          width: 43.6w;
          height: 43.6vw;
        }

        &:nth-of-type(odd) {
          margin-top: -15rem;
        }

        @include respond(tab-port) {
          .project-div {
            width: 335px;
            height: 335px;
          }

          &:nth-of-type(odd) {
            margin-top: 0rem;
          }
        }
      }

      @include respond(tab-port) {
        &:nth-child(odd) {
          margin-top: 0rem;
        }
      }

      @include respond(phone) {
        padding: 0;
      }
    }

    .projects {
      position: relative;
      z-index: 1;
    }
  }

  .section-news {
    position: relative;
    background-color: $color-marine;
    // height: 1250px;
    padding: 15rem 0 1rem 0;
    margin-top: -30rem;
    @include overlay-mesh-grid();
    @include respond(phone) {
      margin-top: -13rem;
    }

    .see-more {
      background-image: url("../../img/script.png");
      padding-top: 15rem;
      padding-bottom: 7rem;
      @include respond(phone) {
        padding-top: 0;
      }

      &-header {
        position: relative;
        z-index: 2;
        color: $color-white;
        font-size: 5rem;
        font-weight: bold;
        margin-bottom: 32px !important;

        @include respond(phone) {
          font-size: 32px;
        }
      }

      .btn-global {
        padding: 2rem 10rem;

        @include respond(phone) {
          padding: 2rem 5rem;
        }
      }
    }

    .news {
      position: relative;
      padding-top: 8rem;
      margin-bottom: 6rem;

      .carousel-div {
        position: relative;

        .carousel-control-next {
          // width: 15%;
          justify-content: start;
          top: 10%;
          left: 8rem;
          z-index: 11;

          @include respond(phone) {
            left: 2.5rem;
          }

          /* .carousel-caption has z-index 10 */
        }

        .carousel-control-prev {
          // width: 15%;
          justify-content: start;
          top: 0%;
          left: 8rem;
          z-index: 11;

          @include respond(phone) {
            left: 2.5rem;
            padding-bottom: 24px;
          }

          /* .carousel-caption has z-index 10 */
        }
      }

      &-header {
        padding-left: 8rem;

        @include respond(phone) {
          padding-left: 20px;
        }

        .heading-secondary {
          position: relative;
        }
      }

      $fragment_width: 25%;
      $fragment_width_respond: 8%;
      $item_third_width: 33%;

      .show-neighbors {
        overflow: hidden;
        // margin-left: 15rem;

        .carousel-indicators {
          margin-right: $fragment_width;
          margin-left: $fragment_width;

          @include respond(phone) {
            margin-right: $fragment_width_respond;
            margin-left: $fragment_width_respond;
          }
        }

        .carousel-control-prev,
        .carousel-control-next {
          // width: 15%;
          display: none;
          justify-content: start;
          left: 8rem;
          z-index: 11;
          /* .carousel-caption has z-index 10 */
        }

        .carousel-inner {
          width: (100% - 2 * $fragment_width) * 4;
          // width: 180%;
          // left: 3 * $fragment_width - 100%;
          margin-left: 10rem;
          // padding-left: 15rem;

          @include respond(phone) {
            width: (100% - 2 * $fragment_width_respond) * 3;
            margin-left: 6rem;
          }
        }

        .carousel-item-next:not(.carousel-item-left),
        .carousel-item-right.active {
          -webkit-transform: translate3d(-1 * $item_third_width, 0, 0);
          transform: translate3d(-1 * $item_third_width, 0, 0);
        }

        .carousel-item-prev:not(.carousel-item-right),
        .carousel-item-left.active {
          -webkit-transform: translate3d(1 * $item_third_width, 0, 0);
          transform: translate3d(1 * $item_third_width, 0, 0);
        }

        .item__third {
          float: right;
          position: relative;
          /* captions can now be added */
          height: 375px;
          width: $item_third_width;

          @include respond(phone) {
            height: 300px;
          }

          .container {
            height: 375px;
            // width: 850px;
            @include respond(phone) {
              height: 201px;
              width: 95%;
              padding: 0;
              margin-left: -20px;
            }
            .row {
              height: 100%;
              justify-content: center;
            }
          }
        }
      }

      @include respond(tab-port) {
        padding-top: 5rem;
      }
    }
  }
}
