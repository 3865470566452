// COLORS
$color-primary: #55c57a;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

// PALM CONSTRUCTION
$color-marine: #093148;
$color-marine-transparent: #07273ac2;
$color-ugly-blue: #2e667d;
$color-dirty-blue: #4b95af;
$color-dark-grey: #797778;
$color-grey: #a2a2a3;

$primary: #2e667d !default;

// FONT
$default-font-size: 1.5rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;
