.header {
  background-image: url("../../img//mesh-grid/mesh-grid.png"),
    url(../../img/sketch/sketch.jpg);
  // height: 800px;
  background-size: 40% 100%, 62% 100%;
  background-repeat: no-repeat;
  // background-repeat-x: repeat;
  background-position: left, top right;
  height: 100vh;
  position: relative;

  @include respond(big-desktop) {
    background-size: 52% 100%, 50% 100%;
  }

  @include respond(phone) {
    height: 455px;
    background-size: 100% 100%, 100% 100%;
    background-position: left, right -80px top;
  }

  &__logo-box {
    position: absolute;
    z-index: 2000;
    top: 4rem;
    left: 75px;
    background-image: url("../../img/palm-logo/image-1@2x.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
    width: 21vh;
    height: 19vh;
    min-height: 120px;
    min-width: 130px;
    @include respond(phone) {
      width: 84px;
      height: 75px;
      min-width: 0;
      min-height: 0;
      top: 2.5rem;
      left: 2.5rem;
    }
  }

  &__logo {
    // min-height: 10rem;
    // height: 100%;
  }

  &__text-box {
    position: absolute;
    top: 40%;
    left: 8rem;
    // left: 5%;
    @include respond(phone) {
      max-width: 216px;
      left: 2.5rem;
    }
  }
}
