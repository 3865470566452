.footer {
  // position: absolute;
  // bottom: 0;
  background-color: $color-marine;
  position: relative;
  width: 100%;
  padding: 15rem 10rem 2rem 10rem;
  font-size: 1.4rem;
  color: $color-white;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include overlay-mesh-grid() {
    background-position: bottom;
  }

  @include respond(tab-port) {
    padding: 2rem 2rem;
    // font-size: 1rem;
  }

  &__header {
    text-align: center;
    font-size: 7.2rem;
    text-transform: uppercase;
    text-align: center;
    font-size: 7.5rem;
    letter-spacing: 1px;
    position: relative;
    z-index: 2;

    a {
      color: $color-white;
      font-weight: bold;

      &:hover {
        color: $color-dirty-blue;
        text-decoration: none;
      }
    }

    @include respond(tab-port) {
      font-size: 3.2rem;
    }
  }

  &__header-sub {
    text-align: center;
    font-size: 1.8rem;
    color: $color-white;
    position: relative;
    z-index: 2;
    letter-spacing: 1px;

    @include respond(tab-port) {
      font-size: 1rem;
    }
  }

  &__navigation {
    position: relative;
    z-index: 2;
    border-top: 1px solid $color-white;
    width: 85%;
    text-align: center;
    margin-top: 1rem;
    padding-top: 3rem;
    display: inline-block;

    @include respond(tab-port) {
      width: 100%;
      text-align: center;
    }
  }

  &__list {
    list-style: none;

    display: flex;
    justify-content: center;
    @include respond(tab-port) {
      // justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  &__item {
    display: inline-block;
    border-right: 1px solid $color-white;
    // flex: 1;

    &:last-child {
      border-right: none;

      @include respond(tab-port) {
        min-width: 21%;
        width: auto;
      }
    }

    &:not(:last-child) {
      margin-right: 3rem;

      @include respond(tab-port) {
        margin-right: 1rem;
      }
    }

    @include respond(tab-port) {
      display: inline;
      // flex: 1;
      width: 21%;

      &:nth-child(4n) {
        border-right: none;
      }
      &:nth-child(4n):after {
        content: " ";
        display: block;
      }

      margin-bottom: 1rem;
    }
  }

  &__link {
    &:link,
    &:visited {
      position: relative;
      color: $color-white;
      display: inline-block;
      transition: all 0.2s;
      padding-right: 3rem;

      @include respond(tab-port) {
        padding-right: 1.5rem;
      }
    }

    &:hover {
      color: $color-dirty-blue;
      text-decoration: none;
    }

    &.active-link {
      text-decoration: underline;
      font-weight: 700;
    }
  }

  &__copyright {
    position: relative;
    text-align: center;
    padding-top: 2rem;
    margin-bottom: 1rem;

    @include respond(tab-port) {
      width: 100%;
      float: none;
    }
  }
}
