.careers-main {
  overflow: auto;

  .header-careers {
    position: relative;
    overflow: auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    // background-image: url(../../img/mesh-grid/mesh-grid.png);
    .button--isi {
      margin: 0 auto;
      margin-bottom: 300px;
      padding: 2rem 5rem;
      margin-top: 40px;
      // width: 45rem;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 0;
      border: 2px solid $color-dirty-blue;
      @include respond(phone) {
        padding-left: 3rem;
        padding-right: 3rem;
        line-height: 0;
        width: 60%;
        height: 42px;
        font-size: 11px;
        letter-spacing: 0.5px;
        border: 1px solid $color-dirty-blue;
      }
    }
    .careers-title {
      display: table;
      margin: 0 auto;
      padding-top: 40vh;
      font-size: 6.2rem;
      text-transform: uppercase;
      font-weight: bold;
      color: $color-marine;
      @include respond(phone) {
        padding-top: 20vh;
        font-size: 3.1rem;
      }
    }

    .secondary-title {
      display: table;
      margin: 0 auto;
      color: #797778;
      font-size: 24px;
      text-align: center;
      font-weight: 500;
      margin-bottom: 116px;
      @include respond(phone) {
        margin: 10 auto;

        font-size: 1.6rem;
        margin-bottom: 48px;
      }
    }

    .careers-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 3.5rem;
      @include respond(phone) {
        flex-direction: column;
      }
    }

    .career-instance {
      @include respond(phone) {
        font-size: 2rem;
        padding: 0;
        max-width: 100%;
      }

      position: relative;
      display: flex;
      flex-direction: row;
      margin-bottom: 4rem;
      flex: 1;
      min-width: 50%;
      padding: 0 5rem;
      max-width: 50%;

      @include respond(big-desktop) {
        padding: 0 3rem;
      }
      // margin-left: 75px;
      .career-type {
        @include respond(phone) {
          font-size: 2rem;
        }
        font-size: 2.8rem;
        color: #797778;
        font-weight: 600;
      }

      .svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5rem;
        display: block;
        z-index: 1;
        @include respond(phone) {
          bottom: -3rem;
          right: -3rem;
        }

        .career-apply {
          @include respond(phone) {
            height: 40px;
            width: 40px;
            right: 24px;
            top: 0px;
          }
          position: absolute;
          // right: 10rem;
          @include circleButton(60px);

          font-size: 1.5rem;
          background-image: url("../../img/oval-grey/oval@3x.png"),
            url("../../img/careers/back-copy2x.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 60px 60px, 12px;
          position: absolute;
          bottom: 0.5rem;
          left: 0.5rem;
          transition: background-color 1s linear;

          &:hover {
            line-height: 59px;
            border: 2px solid transparent;
            border-radius: 50%;
            border-style: solid;
            background-color: rgba(203, 200, 200, 0.126);
          }

          @include respond(phone) {
            @include circleButton(36px);
            top: 3px;
            left: 3px;
            font-size: 6px;
            background-size: 36px 36px, 9px;
            line-height: normal;
            text-align: center;

            &:hover {
              line-height: normal;
            }
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      svg {
        width: 70px;
        height: 70px;
        circle {
          cx: 35px;
          cy: 35px;
          r: 30px;
        }
        @include respond(phone) {
          width: 42px;
          height: 42px;
          circle {
            cx: 21px;
            cy: 21px;
            r: 18px;
          }
        }
      }

      .anicircle {
        fill: none;
        stroke-width: 2px;
        stroke: $color-dark-grey;
        stroke-dasharray: 400 400;
        stroke-dashoffset: 400;
        transition: stroke-dashoffset 2s linear;
      }

      .svg:hover .anicircle {
        stroke-dashoffset: 5;
      }

      // border-radius: 50%;

      // border: 3px dashed gray;

      .career-apply:before {
        // position: absolute;
        // content: "";
        // height: calc(100% + 3px);
        // width: calc(100% + 3px);
        // // border: 3px dashed grey;
        // // top: -2px;
        // left: -2px;
        // border-radius: inherit;
        // &:hover {
        //   animation: spin 10s linear infinite;
        // }
      }

      .career-apply:hover:before {
        animation: spin 8s linear infinite;
        cursor: pointer;
      }

      @keyframes spin {
        100% {
          transform: rotateZ(360deg);
        }
      }
      &:nth-child(even) {
        padding-left: 10rem;
        .svg {
          right: 5rem;
        }
        @include respond(phone) {
          padding-left: 0;
          .svg {
            right: -3rem;
          }
        }
      }
    }
  }

  .career-application {
    background-repeat: repeat;
    background-attachment: scroll;
    background-position: center center;
    background-color: $color-marine;
    @include overlay-mesh-grid(0.15) {
      height: 180%;
      width: 135%;
      background-size: contain;
    }

    .second-div {
      float: left;
      clear: none;
      position: relative;
      height: 1200px;
      width: 100%;
      padding-left: 8.2%;
      @include respond(phone) {
        position: relative;
        height: 700px;
      }

      .fillapp-title {
        position: relative;
        @include respond(phone) {
          position: absolute;
          max-width: 85%;
          font-size: 3.2rem;
          color: $color-marine;
          top: -240px;
          margin-top: 0;
          text-align: center;
          letter-spacing: normal;
        }
        line-height: 1.2;
        max-width: 40.25%;
        font-size: 4.8rem;
        color: white;
        font-weight: bold;
        margin-top: 120px;
        margin-bottom: 2rem;
        letter-spacing: 2.18px;
      }

      .fillapp-secondary {
        @include respond(phone) {
          position: absolute;
          max-width: 90%;
          top: -130px;
          color: gray;
          font-size: 12px;
          letter-spacing: 0.55px;
          font-weight: normal;
        }
        position: relative;
        max-width: 39%;
        font-size: 2.4rem;
        color: white;
        font-weight: 500;
        letter-spacing: 1.09px;
      }
    }
  }

  .application-form {
    @include respond(phone) {
      position: absolute;
      top: -100px;
      width: 90%;
      margin-bottom: 143px;
    }
    position: absolute;
    width: 45vw;
    background-color: white;
    box-shadow: 0 48px 56px 0 rgba(6, 33, 48, 0.24);
    right: 5%;
    top: -120px;
    z-index: 2;

    .app-title {
      @include respond(phone) {
        position: relative;
        font-size: 1.8rem;
      }

      display: table;
      margin: 0 auto;
      padding-top: 50px;
      font-size: 3.4rem;
      color: $color-marine;
      font-weight: bold;
      letter-spacing: 1px;
    }

    .upload-div {
      @include respond(phone) {
        margin-left: 10%;
        margin-bottom: 80px;
      }
      position: relative;
      display: flex;
      flex-direction: row;
      position: relative;
      margin-left: 16%;
      margin-top: 65px;
      margin-bottom: 65px;

      input[type="file"] {
        height: 70px;
        position: absolute;
        top: 5px;
        left: 5px;
        opacity: 0;
        z-index: 10000;
        cursor: pointer;
      }

      input[type="file"]::-webkit-file-upload-button {
        height: 70px;
        position: absolute;
        top: 5px;
        left: 5px;
        opacity: 0;
        z-index: 10000;
        cursor: pointer;
      }

      .upload-button {
        cursor: pointer;
        @include respond(phone) {
          height: 48px;
          width: 48px;
          border: 1.5px solid $color-dirty-blue;
        }
        box-shadow: 4px 8px 16px 0 rgba(46, 102, 125, 0.24);
        position: relative;
        border: 2px solid $color-dirty-blue;
        height: 80px;
        width: 80px;
        background-color: transparent;

        border-radius: 50%;

        img {
          @include respond(phone) {
            height: 35px;
            width: 35px;
          }
          width: 52px;
          height: 52px;
          margin-left: 4%;
          margin-top: 5px;
        }
      }

      .upload-title {
        @include respond(phone) {
          font-size: 16px;
          font-weight: 500px;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.44px;
          padding-top: 10px;
        }

        padding-top: 20px;
        margin-left: 5%;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.67px;
        color: #797778;
      }
    }

    .button--isi {
      // transition: all 3s;
      @include respond(phone) {
        bottom: 30px;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 40px;
        font-size: 11px;
        height: 42px;
        line-height: 0;
      }
      font-size: 14px;
      margin-bottom: 75px;
      margin-left: 16%;
      width: 76%;
      font-weight: bold;
    }
  }
}
