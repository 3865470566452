.navigation-lg {
  position: absolute;
  z-index: 100;
  top: 5.5rem;
  right: 7rem;
  // padding: 0 8rem;
  // background-color: $color-marine;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include respond(phone) {
    top: 0px;
    right: 0;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li {
    float: left;
  }

  li a {
    display: block;
    padding: 8px 1.5rem;

    &:hover {
      color: $color-dirty-blue;
      opacity: 1;
      text-decoration: none;
    }
  }

  .first_row {
    padding-right: 1.5rem;

    a {
      font-size: 1.6rem;
      font-weight: 400;
      color: $color-black;
      opacity: 0.6;
    }

    #dropdown-arrow {
      margin-left: 1rem;
    }

    .clicked {
      position: relative;
      text-decoration: none;

      &:after {
        content: " ";
        position: absolute;
        border-bottom: 2px solid $color-marine;
        display: block;
        margin-right: 1rem;
        top: 4rem;
        width: 100%;
      }
    }

    display: flex;
    flex-direction: row;
  }

  .second_row {
    padding-top: 0.5rem;

    @include respond(phone) {
      padding-top: 0rem;
    }

    a {
      // color: $color-marine;
      font-size: 1.6rem;
      font-weight: 600;
      text-transform: uppercase;

      button {
        position: relative;
        z-index: 1000;
        border-radius: 36px;
        border: 1px solid transparent;
        padding: 0.5rem 4.2rem;
        border: 0px solid transparent;
        outline: none !important;
        background-color: #093148;
        color: #fff;
        margin-top: -10px;

        @include respond(tab-port) {
          visibility: visible;
          position: absolute;
          z-index: 1000;
          right: 10rem;
          top: 0.6rem;
          margin-top: 0px;
        }

        @include respond(phone) {
          right: 9rem;
          border-radius: 36px;
          height: 36px;
        }
      }
    }

    .active-link {
      position: relative;
      color: $color-ugly-blue;
      text-decoration: none;

      &:after {
        content: " ";
        position: absolute;

        border-bottom: 2px solid $color-ugly-blue;
        display: block;
        margin-right: 1rem;
        bottom: 1rem;
        width: 25%;
      }
    }

    .contact {
      padding: 8px 0.5rem 8px 1rem;

      @include respond(phone) {
        padding: 0;
      }
    }

    display: flex;
    flex-direction: row;
  }

  @include respond(tab-port) {
    visibility: hidden;
  }
}

.menu {
  cursor: pointer;
  position: relative;
}

.menu_arrow {
  display: none;
}

.menu_arrow-invert {
  &:checked + label {
    & + .menu_options {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.menu_options {
  opacity: 0;
  width: 25vw;
  background-color: #f6fafb;
  position: absolute;
  z-index: 2000;
  top: 4.2rem;
  right: 1.5rem;
  padding: 3.1rem 3rem 1.5rem 3rem;
  pointer-events: none;
  transition: all 0.6s ease-in-out;

  @include respond(big-desktop) {
    width: 20vw;
  }
  &-header {
    width: 100%;
    font-size: 1.8rem;
    font-weight: 500;
    color: $color-dark-grey;
    border-bottom: 1px solid $color-grey;
  }

  &-websites {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 1rem;

    li {
      min-width: 120px;
      flex: 1;
      color: $color-grey;
      transition: all 0.3s ease-in-out;

      &:nth-of-type(even) {
        text-align: right;
      }

      .website {
        font-size: 1.3rem;
        color: $color-grey;
        padding: 0.6rem 0;
      }
    }
  }
}
