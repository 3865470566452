body {
  font-family: "Avenir Next", sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/
  line-height: 1.7;
  color: $color-grey-dark;
}

.heading-primary {
  color: $color-marine;
  backface-visibility: hidden;
  margin-bottom: 6rem;

  &--main {
    display: block;
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 1.2;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    @include respond(phone) {
      font-family: 1rem;
      font-size: 18px;
    }

    /*
        animation-delay: 3s;
        animation-iteration-count: 3;
        */
  }

  &--sub {
    color: $color-ugly-blue;
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    animation: moveInRight 1s ease-out;

    @include respond(phone) {
      font-size: 9px;
    }
  }
}

.heading-secondary {
  font-size: 6.5rem;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-block;
  color: white;
  letter-spacing: 0.2rem;
  transition: all 0.2s;

  @include respond(tab-port) {
    font-size: 3rem;
  }

  @include respond(phone) {
    font-size: 32px;
    margin-top: 45px;
  }
}

.heading-tertiary {
  font-size: $default-font-size;
  font-weight: 700;
  text-transform: uppercase;
}

.paragraph {
  color: $color-white;
  font-size: 20px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 40px;

  @include respond(phone) {
    font-size: 13px;
    max-width: 328px;
    font-weight: 400;
  }

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}

.section-header-sub {
  font-weight: 500;
  font-size: 1.8rem;
  color: $color-dirty-blue;

  @include respond(phone) {
    font-size: 0.8rem;
    margin-bottom: 8px;
  }
}

.section-header {
  font-weight: bold;
  color: $color-marine;
  font-size: 6.5rem;
  text-transform: uppercase;

  &-projects {
    font-weight: bold;
    color: $color-marine;
    text-transform: uppercase;
    font-size: 6rem;
    max-width: 50%;

    @include respond(phone) {
      font-size: 26px;
      max-width: 160px;
    }
  }

  @include respond(phone) {
    font-size: 26px;
  }
}

.OUR-STORY {
  max-width: 100%;
  width: auto;
  height: auto;
  font-size: 6rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #093148;
  display: inline-block;
  justify-content: start;
  flex-basis: auto;
  padding-bottom: 1rem;

  @include respond(tab-land) {
    font-size: 64px;
    max-width: 511px;
  }

  @include respond(tab-port) {
    font-size: 50px;
    max-width: 411px;
  }

  @include respond(phone) {
    font-size: 36px;
  }
}

.About_Description {
  overflow: hidden;
  // margin: auto;
  max-width: 810px;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;

  color: #303030;

  @include respond(tab-land) {
    font-size: 17px;
    max-width: 511px;
  }

  @include respond(tab-port) {
    font-size: 15px;
    max-width: 411px;
  }

  @include respond(phone) {
    font-size: 12px;
    max-width: 311px;
    line-height: 20px;
    letter-spacing: 0;
  }
}

.Founded-in-2012-Pal {
  text-align: left;
  position: relative;
  max-width: 100%;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  @include SetDescriptionMargins(4.6rem, 19.6875rem, 4.6rem, 14.0625rem);
}

.Our-strength-is-deli {
  text-align: left;
  position: relative;
  max-width: 100%;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  @include SetDescriptionMargins(4.6rem, 10.3125rem, 4.6rem, 23.4375rem);
}

.At-Palm-Construction {
  text-align: left;
  position: relative;
  max-width: 100%;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  @include SetDescriptionMargins(4.6rem, 16.25rem, 4.6rem, 17.5rem);
}

.Correspondingly-due {
  text-align: left;
  position: relative;
  max-width: 100%;
  font-size: 1.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  @include SetDescriptionMargins(4.6rem, 19.375rem, 4.6rem, 14.375rem);
}

@media only screen and (max-width: 47.5rem) {
  .Founded-in-2012-Pal {
    margin: auto;
  }

  .At-Palm-Construction {
    margin: auto;
  }

  .Correspondingly-due {
    margin: auto;
  }

  .Our-strength-is-deli {
    margin: auto;
  }
}

.text-style-1 {
  font-weight: 600;
  max-width: 40.25rem;
}

.text-style-2 {
  font-weight: 500;
  max-width: 66.25rem;
}
