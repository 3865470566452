.navigation {
  display: none;

  @include respond(tab-port) {
    display: block;
  }

  &__checkbox {
    display: none;
  }

  &__button {
    background-color: $color-marine;
    height: 7rem;
    width: 7rem;
    position: absolute;
    top: 6rem;
    right: 6rem;
    border-radius: 50%;
    z-index: 1000;
    box-shadow: 1rem 1rem 3rem rgba($color-black, 0.1);
    text-align: center;
    cursor: pointer;

    @include respond(tab-port) {
      top: 4rem;
      right: 4rem;
    }

    @include respond(phone) {
      height: 4.5rem;
      width: 4.5rem;
      top: 5.2rem;
      right: 2rem;
    }
  }

  &__background {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    position: fixed;
    top: 6.5rem;
    right: 6.5rem;
    // background-image: url('../../img/sketch/sketch@2x.jpg');
    z-index: 2;
    transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);

    @include respond(tab-port) {
      top: 4.5rem;
      right: 4.5rem;
    }

    @include respond(phone) {
      top: 3.5rem;
      right: 3.5rem;
    }
  }

  &__nav {
    overflow-y: hidden;
    visibility: hidden;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    background-image: url(../../img/mobile/group-8@2x.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    opacity: 0;
    width: 0;
    transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  &__list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: left;
    width: 50%;
  }

  &__item {
    margin: 0.8rem;
    position: relative;

    &.add-border {
      position: relative;

      &::after {
        content: " ";
        /* height: 50px; */
        width: 80px;
        position: absolute;
        bottom: -15px;
        left: 2rem;
        color: white;
        border-top: 8px solid $color-marine;
        margin-bottom: 2rem;

        @include respond(tab-port) {
          bottom: -5px;
          border-top-width: 3px;
        }

        @include respond(phone) {
          bottom: -25px;
          border-top-width: 4px;
          width: 15%;
        }
      }
    }
  }

  &__link {
    &:link,
    &:visited {
      display: inline-block;
      font-size: 3rem;
      font-weight: bold;
      padding: 1rem 2rem;
      color: $color-marine;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 2.4px;
      // background-image: linear-gradient(120deg, transparent 0%, transparent 50%, $color-white 50%);
      // background-size: 220%;
      transition: all 0.4s;

      span {
        margin-right: 1.5rem;
        display: inline-block;
      }
    }

    &:hover,
    &:active {
      background-position: 100%;
      color: $color-marine;
      // transform: translateX(1rem);
    }
  }

  &__link-2 {
    &:link,
    &:visited {
      display: inline-block;
      font-size: 1.6rem;
      font-weight: 500;
      padding: 0.6rem 3rem;
      color: $color-dark-grey;
      text-decoration: none;
      letter-spacing: 1px;
      transition: all 0.4s;
      opacity: 0.9;

      span {
        margin-right: 1.5rem;
        display: inline-block;
      }
    }

    &:hover,
    &:active {
      background-position: 100%;
      color: $color-marine;
      // transform: translateX(1rem);
    }
  }

  //FUNCTIONALITY
  &__checkbox:checked ~ &__background {
    transform: scale(80);
  }

  &__checkbox:checked ~ &__nav {
    visibility: visible;
    opacity: 1;
    width: 100%;
  }

  //ICON
  &__icon {
    position: relative;
    margin-top: 3.5rem;

    @include respond(phone) {
      margin-top: 2.2rem;
    }

    &,
    &::before,
    &::after {
      width: 3rem;
      height: 2px;
      background-color: $color-white;
      display: inline-block;

      @include respond(phone) {
        width: 2rem;
        height: 1px;
      }
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all 0.2s;
    }

    &::before {
      top: -0.8rem;

      @include respond(phone) {
        top: -0.75rem;
      }
    }

    &::after {
      top: 0.8rem;

      @include respond(phone) {
        top: 0.75rem;
      }
    }
  }

  &__button:hover &__icon::before {
    top: -1rem;
  }

  &__button:hover &__icon::after {
    top: 1rem;
  }

  &__checkbox:checked + &__button &__icon {
    background-color: transparent;
  }

  &__checkbox:checked + &__button &__icon::before {
    top: 0;
    transform: rotate(135deg);
  }

  &__checkbox:checked + &__button &__icon::after {
    top: 0;
    transform: rotate(-135deg);
  }

  .palm-name {
    color: $color-marine;
    font-size: 2.3rem;
    color: #093148;
    letter-spacing: 1px;
  }

  div.back-icon {
    height: 2rem;
    background-image: url("../../img/back_marine/back@2x.png");
    background-repeat: no-repeat;
    margin-right: 1rem;
  }

  .menuBack {
    position: relative;
    height: auto;
    width: 20%;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    margin-bottom: 2rem;
    // border: 1px solid grey;

    @include respond(tab-port) {
      width: 50%;
    }
    margin-left: 5%;
    &:hover {
      cursor: pointer;
    }

    img {
      height: 14px;
      // width: 14px;
      margin-top: auto;
      margin-bottom: auto;
    }
    div {
      font-weight: 500;
      font-size: 1.6em;
      margin-left: 6%;
      color: $color-marine;

      word-wrap: break-word;
    }
  }

  &__list#websites-list {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: left;
    width: 75%;
  }
}
