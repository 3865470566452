.btn-global {
  // color: $color-white;
  z-index: 1;

  &,
  &:link,
  &:visited {
    // color: $color-white;
    text-transform: uppercase;
    text-decoration: none;
    padding: 2rem 3.5rem;
    display: inline-block;
    // position: relative;
    font-size: 1.3vw;
    font-weight: 500;
    // overflow: hidden;

    //Change for the <button> element
    // border: none;
    cursor: pointer;

    @include respond(phone) {
      font-size: 1.5rem;
    }
  }
}
