// Styling for the portfolio section
.mission-container {
  margin-left: 225px;
  margin-right: 190px;

  @media screen and (max-width: 768px) {
    margin-left: 25px;
    margin-right: 25px;
  }
}

.vision-section {
  padding: 10rem 0;

  h2.section-heading {
    font-size: 3.5rem;

    margin-top: 0;
    margin-bottom: 15px;
  }

  @include respond(tab-port) {
    padding: 5rem 2rem;
  }

  .container {
    padding: 0 4rem 0 10rem;

    @include respond(tab-port) {
      padding: 0 1rem;
    }
  }
}

.border_fade1 {
  border-right: solid;
  border-bottom: solid;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-position: 0 100%, 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px, 1px 100%;
  background-image: linear-gradient(
      to left,
      $color-white 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(to top, $color-white 0%, rgba(0, 0, 0, 0) 100%);

  @media screen and (max-width: 437px) {
    border-image: linear-gradient(to top, white, rgba(0, 0, 0, 0)) 1 100%;
  }
}

.border_fade2 {
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-position: 0 100%, 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px, 1px 100%;
  background-image: linear-gradient(to left, $color-white, $color-white),
    linear-gradient(to top, $color-white 0%, rgba(0, 0, 0, 0) 100%);

  @media screen and (max-width: 437px) {
    border-right: 0;
    border-image: linear-gradient(to right, white, rgba(0, 0, 0, 0)) 1 1 10% 20%;
  }
}

.border_fade3 {
  border-bottom: 1px solid transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  background-image: linear-gradient(
    to right,
    $color-white 0%,
    rgba(0, 0, 0, 0) 100%
  );

  @media screen and (max-width: 437px) {
    border-right: solid;
  }
}

.border_fade4 {
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-position: 0 0, 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px, 1px 100%;
  background-image: linear-gradient(
      to left,
      $color-white 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(to bottom, $color-white 0%, rgba(0, 0, 0, 0) 100%);

  @media screen and (max-width: 437px) {
    border-right: 0;
    border-bottom: solid;
    border-image: linear-gradient(to right, white, rgba(0, 0, 0, 0)) 1 1 10% 20%;
  }
}

.border_fade5 {
  border-right: solid;
  border-image: linear-gradient(to bottom, white, rgba(0, 0, 0, 0)) 1 200%;
}

.border_fade6 {
}

.To-form-long-term-r {
  height: auto;
  max-width: 1025px;
  font-size: 2rem;
  font-weight: 400;
  color: white;
  margin-bottom: 7rem;

  @include respond(phone) {
    max-width: 328px;
    font-size: 11px;
  }
}

.section-mission {
  // background-image: url(../../img/group-12.png);
  position: relative;

  #portfolio {
    @include overlay-mesh-grid() {
      background-position: bottom;
    }

    background-color: $color-marine;

    .MissionMargins {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      margin-top: 5rem;
      @include respond(phone) {
        margin-top: 0;
      }
      .row {
        position: relative;
        justify-content: center;

        .gradient-line {
          position: absolute;
          z-index: 100;
          left: 33.33333%;
          height: 100%;
          width: 1px;
          background-size: 1px 100%;
          background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            $color-white 50%,
            rgba(0, 0, 0, 0) 100%
          );

          @include respond(tab-port) {
            left: 50%;
            // background-size: 1px 97%;
          }
        }

        .gradient-line-1 {
          position: absolute;
          z-index: 4;
          right: 33.33333%;
          height: 100%;
          width: 1px;
          background-size: 1px 100%;
          background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            $color-white 50%,
            rgba(0, 0, 0, 0) 100%
          );

          @include respond(tab-port) {
            visibility: hidden;
          }
        }

        .gradient-line-horizontal {
          position: absolute;
          z-index: 4;
          top: 50%;
          width: 100%;
          height: 1px;
          background-size: 100% 1px;
          background-image: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0%,
            $color-white 50%,
            rgba(0, 0, 0, 0) 100%
          );
          background-repeat: no-repeat;

          @include respond(tab-port) {
            top: 33.33333%;
            background-size: 99% 1px;
          }
        }

        .gradient-line-horizontal-1 {
          visibility: hidden;
          position: absolute;
          z-index: 4;
          bottom: 33.33333%;
          width: 100%;
          height: 1px;
          background-size: 100% 1px;
          background-image: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0%,
            $color-white 50%,
            rgba(0, 0, 0, 0) 100%
          );
          background-repeat: no-repeat;

          @include respond(tab-port) {
            visibility: visible;
            background-size: 99% 1px;
          }
        }
      }

      .row:before,
      .row:after {
        display: none !important;
      }

      .coreValues {
        font-size: 20px;

        font-weight: 500;
        color: white;
        // margin-right: 25px;
        justify-content: center;
        // padding: 0px 10px;
        display: flex;
        align-items: flex-start;
        width: 20px;
        margin-right: 4rem;

        @media screen and (max-width: 768px) {
          width: 0;
          height: 0;
          font-size: 0px;
          margin-right: 0;
        }
      }

      .coreValues > p {
        transform: rotateZ(-90deg);
        margin-top: 150px;

        white-space: nowrap;

        @media screen and (max-width: 768px) {
          width: 0;
          height: 0;
          content: "";
        }
      }
    }

    .Mission {
      margin-bottom: 4rem;
      max-width: 538px;
      font-size: 6rem;
      font-weight: bold;
      color: white;
      position: relative;
      letter-spacing: 2px;

      @media screen and (max-width: 768px) {
        font-size: 36px;
        margin-bottom: 30px;
      }
    }

    .Mission:after {
      content: "";
      margin: auto 0;
      position: absolute;
      width: 80px;
      left: 0;
      bottom: 5px;
      border-bottom: 5px solid white;

      @media screen and (max-width: 437px) {
        border-bottom: 1px solid white;
      }
    }

    .col-xs-6 {
      @include respond(phone) {
        width: 48% !important;
      }
    }

    // @include overlay-grid($color-marine);
    .mission-item {
      height: 300px;
      // margin: 2px -13px;
      // right: 0;
      color: white;

      .portfolio-item {
        height: 100%;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        visibility: hidden;
      }

      // border-width: 1px;
      &:nth-child(1):hover {
        background-image: linear-gradient(
          to top left,
          rgba(75, 149, 175, 0.32) 0%,
          transparent 98%
        );
      }

      &:nth-child(2):hover {
        background-image: linear-gradient(
          to top,
          rgba(75, 149, 175, 0.32) 0%,
          transparent 98%
        );
      }

      &:nth-child(3):hover {
        background-image: linear-gradient(
          to top right,
          rgba(75, 149, 175, 0.32) 0%,
          transparent 98%
        );
      }

      &:nth-child(4):hover {
        background-image: linear-gradient(
          to bottom left,
          rgba(75, 149, 175, 0.32) 0%,
          transparent 98%
        );
      }

      &:nth-child(5):hover {
        background-image: linear-gradient(
          to bottom,
          rgba(75, 149, 175, 0.32) 0%,
          transparent 98%
        );
      }

      &:nth-child(6):hover {
        background-image: linear-gradient(
          to bottom right,
          rgba(75, 149, 175, 0.32) 0%,
          transparent 98%
        );
      }

      @media screen and (max-width: 768px) {
        // max-width: 146px;
        max-height: 146px;
      }

      .portfolio-caption {
        transition: transform 0.2s;
        margin: 0;
        padding-top: 70px;
        background-color: transparent;
        font-weight: 600;
        font-size: 3.8em;
        text-align: center;
        color: white;

        @media screen and (max-width: 768px) {
          font-size: 15px;
        }
      }
    }

    .mission-item:hover {
      // background-color: rgba(75, 149, 175, 0.32);
      // background-size: 90%;

      .portfolio-caption {
        // font-size: 0em;
        display: none;
      }

      .portfolio-item {
        display: flex;
        visibility: visible;
        font-size: 18px;

        font-weight: 400;
        text-align: center;

        @media screen and (max-width: 768px) {
          font-size: 10px;
        }
      }
    }

    * {
      z-index: 2;
    }
  }
}
