.ceo-header {
  text-align: center;
  color: white;
  background-image: url("../../img/ceo/group-9.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;

  .ceo-container {
    position: relative;
    display: flex;
    justify-content: center;
    // align-content: flex-start;
    align-items: flex-start;
    height: 100vh;
    width: 100vw;
    @include overlay-mesh-grid(0.65);

    @include respond(tab-port) {
      // height: 667px;
      padding-top: 10rem;
      align-items: center;
    }

    .CEO-MESSAGE {
      h2 {
        font-size: 7.5rem !important;
        @include respond(phone) {
          font-size: 5rem !important;
        }
      }

      position: absolute;
      // margin: auto;
      top: 40%;

      // height: 100%;
      width: 55%;
      text-align: center;
      background-color: transparent;
      // line-height: 116px;
      color: $color-marine;
      font-weight: bold;

      @include respond(tab-port) {
        top: 40%;
        font-size: 42px;
        max-width: 85%;
        line-height: normal;
        letter-spacing: normal;
        // margin-top: 100px;
      }

      @include respond(phone) {
        font-size: 42px;
        // left: 20%;
        width: 322px;
        line-height: normal;
        letter-spacing: normal;
        // margin-top: 100px;
        // }
      }

      h2 {
        font-size: 8rem;
        font-weight: bold;
        color: $color-marine;

        @include respond(phone) {
          font-size: 5rem;
        }
      }
    }
  }
}

.ceo-description {
  height: auto;
  overflow: hidden;
  // background-image: url("../../img/group-12.png");

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  .desc_container {
    height: auto;
    max-width: 100rem;
    background-color: transparent;
    text-align: center;
    position: relative;
    z-index: 2;

    @include respond(tab-port) {
      margin-left: 7%;
      margin-right: 7%;
    }

    .ceo-image {
      background-size: cover;
      height: 80vh;
      width: 100%;
      background-position: center top;
      margin-top: 4rem;
      position: relative;
      z-index: 2;

      @include respond(tab-port) {
        max-height: 447px;
      }

      @include respond(phone) {
        max-height: 447px;
      }
    }

    .ceo-quote {
      width: 100%;
      text-align: center;
      font-size: 2.2rem;
      font-weight: 600;
      margin-top: 47px;
      color: $color-marine;
      letter-spacing: 1.85px;

      @include respond(tab-port) {
        font-size: 1.6rem;
      }

      @include respond(phone) {
        font-size: 1.2rem;
      }
    }

    .ceo-title {
      color: $color-dark-grey;
      margin: auto;
      max-width: 500px;
      font-size: 15px;
      margin-top: 16px;
      text-align: center;

      @include respond(tab-port) {
        font-size: 18px;
      }

      @include respond(phone) {
        font-size: 12px;
      }
    }

    .history-container {
      margin-top: 39px;
      margin-bottom: 100px;

      .ceo-history {
        color: $color-black;
        margin-top: 20px;
        font-size: 2rem;

        text-align: center;

        @include respond(tab-port) {
          font-size: 18px;
        }

        @include respond(phone) {
          font-size: 12px;
        }
      }
    }
  }
}
