// // Styling for the navbar
// #mainNav {
//   list-style: s;
//   position: absolute;
//   top: 0;
//   right: 0;
//   //   position: fixed;
//   //   top: 0;
//   //   right: 0;
//   background-color: transparent;
//   .navbar-toggler {
//     font-size: 12px;
//     right: 0;
//     padding: 13px;
//     text-transform: uppercase;
//     color: $primary;
//     border: 0;
//     background-color: white;
//     @include heading-font;
//   }
//   .navbar-brand {
//     color: $primary;
//     @include script-font;
//     &.active,
//     &:active,
//     &:focus,
//     &:hover {
//       border-bottom: 10px;
//     }
//   }
//   .navbar-nav {
//     .nav-item {
//       .nav-link {
//         font-size: 18px;
//         font-weight: 400;
//         padding: 0.75em 0;
//         letter-spacing: 1px;
//         color: #093148;
//         @include heading-font;
//         &.active,
//         &:hover {
//           color: $primary;
//           border-bottom: 3px solid $primary;
//         }
//       }
//     }
//   }
// }
// @media (min-width: 992px) {
//   #mainNav {
//     padding-top: 25px;
//     padding-bottom: 25px;
//     -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
//     -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
//     transition: padding-top 0.3s, padding-bottom 0.3s;
//     border: none;
//     background-color: transparent;
//     .navbar-brand {
//       font-size: 1.75em;
//       -webkit-transition: all 0.3s;
//       -moz-transition: all 0.3s;
//       transition: all 0.3s;
//     }
//     .navbar-nav {
//       .nav-item {
//         .nav-link {
//           padding: 1.1em 1em !important;
//         }
//       }
//     }
//     &.navbar-shrink {
//       padding-top: 0;
//       padding-bottom: 0;
//       background-color: $gray-900;
//       .navbar-brand {
//         font-size: 1.25em;
//         padding: 12px 0;
//       }
//     }
//   }
// }
.mainNav {
  align-items: flex-start;
  align-content: flex-start;
}

.topnav {
  overflow: hidden;
  background-color: transparent;
  top: 0;
  right: 0;
  padding-top: 52px;
}

.topnav a {
  float: right;
  display: block;
  color: $color-marine;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 1.5em;
}

.active {
  background-color: #4caf50;
  color: white;
}

.topnav .icon {
  display: none;
}

.topnav a:hover {
  color: $primary;
  border-bottom: 3px solid $primary;
}

.topnav a.contact-text {
  width: 88px;
  height: 25px;
  font-size: 1.5em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  display: inline-block;
}

.topnav a.contact-text {
  width: 175px;
  height: 50px;
  border-radius: 36px;
  background-color: $color-marine;
}

.topnav a.contact-text:hover {
  border-bottom: 0px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child),
  .dropdown .dropbtn {
    display: none;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  .topnav.responsive .dropdown {
    float: none;
  }

  .topnav.responsive .dropdown-content {
    position: relative;
  }

  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
}
