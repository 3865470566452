.accordion-div {
  background-color: transparent;
  border-top: 1px solid $color-white;
  padding: 0 0.5rem;
  // min-height: 100px;
  min-height: 13.5vh;

  @include respond(tab-port) {
    min-height: 10vh;
  }

  &:last-child {
    border-bottom: 1px solid $color-white;
  }
  .accordion {
    background-color: transparent;
    border-top: 1px solid $color-white;
    color: $color-white;
    cursor: pointer;
    padding: 0.2rem;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    font-size: 2rem;
    // margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 13.4vh;

    @include respond(tab-port) {
      height: 10vh;
    }

    .img-icon {
      display: block;
      // margin-top: 10px;
      width: 3vw;
      margin-right: 1.5rem;
    }

    .service-name {
      padding-left: 1.5rem;
      // padding-top: 1rem;
      font-size: 1.6vmax;
      font-weight: 500;
      flex: 1;
    }

    @include respond(phone) {
      .img-icon {
        width: 45px;
      }

      .service-name {
        font-size: 18px;
        padding-left: 0;
      }
    }
  }

  .active,
  .accordion:hover,
  .accordion:focus {
    outline: none;
  }

  .accordion:after {
    content: "\002B";
    color: #fff;
    font-weight: 200;
    float: right;
    font-size: 5rem;
    margin-left: 5px;

    @include respond(phone) {
      font-size: 3rem;
    }
  }

  .active:after {
    content: "\2212";
  }

  .panel {
    font-size: 1.5rem;
    font-weight: 200;
    padding-left: calc(4vw + 35px);
    background-color: transparent;
    color: $color-white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    margin-bottom: 1px;
    border: none;
    @include respond(tab-port) {
      font-weight: 300;
      padding-left: calc(6vw + 35px);
    }
  }
}
