.input {
  @include respond(phone) {
    margin-left: 10%;
    margin-top: 10px;
  }
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-left: 15%;
  width: 76%;

  margin-top: 24px;
}
.input__field {
  @include respond(phone) {
    height: 42px;
    font-size: 12px;
  }
  position: relative;
  display: block;
  float: right;
  padding: 0.8em;
  width: 60%;
  height: 80px;
  font-size: 22px;
  border: none;
  border-radius: 0;
  background: #f0f0f0;
  color: $color-marine;
  font-weight: 400;
  font-family: "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-appearance: none; /* for box shadows to show on iOS */
}

.input__field:focus {
  outline: none;
}

.input__label {
  @include respond(phone) {
    font-size: 12px;
  }
  display: inline-block;
  float: right;
  padding: 0 1em;
  width: 40%;
  color: #696969;
  font-weight: bold;
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input__label-content {
  position: relative;
  display: block;
  padding: 1.6em 0;
  width: 100%;
}

.graphic {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
}

.icon {
  color: #ddd;
  font-size: 150%;
}

/* Individual styles */

/* Chisato */

.input--chisato {
  @include respond(phone) {
    padding-top: 2em;
  }
  padding-top: 4em;
}

.input__field--chisato {
  width: 100%;
  padding: 0.8em 0.5em;
  background: transparent;
  border: 2px solid #aaa;
  -webkit-transition: border-color 0.25s;
  transition: border-color 0.25s;
}

.input__label--chisato {
  width: 100%;
  position: absolute;
  top: 0;
  text-align: left;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  -webkit-transform: translate3d(0, 2.5em, 0);
  transform: translate3d(0, 2.5em, 0);
}

.input__label-content--chisato {
  padding: 0 1em;
  font-weight: 400;
  color: #b5b5b5;
}

.input__label-content--chisato::after {
  content: attr(data-content);
  position: absolute;
  top: -200%;
  left: 0;
  color: $color-dirty-blue;

  font-weight: 800;
}

.input__field--chisato.focused {
  border-color: $color-dirty-blue;
}

.input__field--chisato.focused + .input__label--chisato,
.input--filled .input__label--chisato {
  -webkit-animation: anim-chisato-1 0.25s forwards;
  animation: anim-chisato-1 0.25s forwards;
}

.input__field--chisato.focused
  + .input__label--chisato
  .input__label-content--chisato,
.input--filled .input__label-content--chisato {
  -webkit-animation: anim-chisato-2 0.25s forwards ease-in;
  animation: anim-chisato-2 0.25s forwards ease-in;
}

@keyframes anim-chisato-1 {
  0%,
  70% {
    -webkit-transform: translate3d(0, 3em, 0);
    transform: translate3d(0, 3em, 0);
  }
  71%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes anim-chisato-2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  70%,
  71% {
    -webkit-transform: translate3d(0, 175%, 0);
    transform: translate3d(0, 175%, 0);
    opacity: 0;
    -webkit-animation-timing-function: ease-out;
  }
  100% {
    color: transparent;
    -webkit-transform: translate3d(0, 180%, 0);
    transform: translate3d(0, 180%, 0);
  }
}
.input-textarea {
  @include respond(phone) {
    height: 104px;
  }
  height: 240px;
}
