.partners-main {
  overflow: auto;

  .partners-header {
    height: auto;
    position: relative;
    overflow: auto;
    // background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    background-color: white;
  }

  .partners-container {
    position: relative;
    margin-left: 5%;
    margin-right: 5%;

    .partners-title {
      @include respond(phone) {
        font-size: 3.1rem;
        padding-top: 20vh;
      }
      position: relative;
      display: table;
      margin: 0 auto;
      padding-top: 40vh;
      font-size: 6.2rem;
      text-transform: uppercase;
      font-weight: bold;
      color: $color-marine;
    }

    .partners-collection {
      display: flex;
      flex-wrap: wrap;
      margin-top: 99px;
      @include respond(phone) {
        margin-top: 50px;
      }

      .normal-margins {
        @include respond(phone) {
          flex: 0 50%;
        }
        display: none;
        margin: 0 auto;
        margin-bottom: 3rem;
      }

      div {
        position: relative;

        &:hover {
          // background-color: rgba(202, 202, 202, 0.48);

          button {
            display: none;
            z-index: 33;
          }
        }

        text-align: center;
        width: 28%;
        height: 200px;

        @include respond(phone) {
          height: 100px;
        }
        img {
          max-width: 100%;
          max-height: 100%;
        }

        button {
          position: absolute;
          margin: 0 auto;
          top: 40%;
          left: 50%;
          transform: translateX(-50%);
          display: none;
        }
      }
    }

    .button-load {
      display: table;
      margin: 0 auto;
      width: 31.25%;
      margin-top: 5rem;
      margin-bottom: 5rem;
      // border: 2px solid $color-dirty-blue;
      font-weight: 600;
      @include respond(phone) {
        width: 175px;
      }
    }
  }
}
