.filter-button {
  z-index: 2010;
  background-color: $color-white;
  position: fixed;
  bottom: 5rem;
  left: 4rem;
  height: 50px;
  width: 50px;
  border-radius: 50%;

  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;

  @include respond(tab-port) {
    height: 5rem;
    width: 5rem;
  }

  &.open-filter {
    background-color: $color-dirty-blue;
    background-image: url("../../img/filter/filter@2x.png");

    &:hover {
      background-color: $color-dirty-blue;
      cursor: pointer;
    }

    @include respond(tab-port) {
      left: unset;
      right: 5rem;
    }
  }

  &.close-filter {
    background-size: 100%;
    background-color: $color-white;
    background-image: url("../../img/plus/plus@3x.png");
    box-shadow: 0 16px 32px 0 rgba(9, 49, 72, 0.32);

    &:hover {
      background-color: $color-white;
    }
    @include respond(tab-port) {
      position: fixed;
      top: 5vh;
      bottom: unset;
      left: unset;
      right: 5rem;
    }
  }
}
.filter-background {
  display: none;
  z-index: 2000;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  @include respond(tab-port) {
    height: calc(200%);
    background-color: rgb(255, 255, 255);
  }
}

.filter-modal {
  display: none;
  height: 80vh;
  width: 58vw;
  background-color: $color-white;
  bottom: 7rem;
  margin-top: 10px;
  z-index: 2000;
  left: 6.5rem;
  position: fixed;

  @include respond(tab-port) {
    height: calc(100% + 50vh);
    width: 100%;
    bottom: 0;
    left: 0rem;
  }

  .reset-filter-sm {
    position: relative;
    text-align: center;
    a.sm {
      width: 100%;
      margin-top: 2rem;
      font-size: 1.6rem;
      letter-spacing: 0.73px;
      color: black;
      text-decoration: underline;
      cursor: pointer;
      display: none;

      @include respond(tab-port) {
        display: block;
      }
    }
  }
}
