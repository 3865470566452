.sidenav {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  top: 33%;
  left: 0;

  z-index: 10;

  a {
    font-size: 1.5rem;
    color: $color-ugly-blue;
    opacity: 0.7;
    transform: rotateZ(-90deg);
    padding: 5rem 4rem;
    font-weight: 500;
    cursor: pointer;

    @include respond(tab-port) {

      padding: 3rem 2rem;
    }

    &.active-link {
      opacity: 1;
      position: relative;

      &:hover {
        text-decoration: none;
      }

      &::before {
        content: " ";
        /* height: 50px; */
        width: 20%;
        position: absolute;
        top: 60%;
        left: 4rem;
        color: $color-ugly-blue;
        border-top: 2px solid $color-ugly-blue;

        @media (max-width: 376px) {
          top: 68%;
        }
      }
    }
  }

  @include respond(tab-port) {
    width: 100%;
    // position: relative;
    top: 15rem;
    flex-direction: row;

    a {
      color: $color-ugly-blue;
      font-weight: 600;
      font-size: 1.5rem;
      transform: none;
    }
  }
}