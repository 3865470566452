.section-contact {
  overflow: auto;

  .header-contact {
    position: relative;
    background-image: url("../../img/contact/group-3.jpg");
    background: white !important;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;

    display: flex;
    flex-direction: row;
    @include respond(phone) {
      flex-direction: column;
      align-items: center;
    }

    .contact-div {
      background: white;
      @include respond(phone) {
        width: 100%;
        position: relative;
        margin-top: 150px;
        // margin-left: 6%;
        margin-bottom: 50px;
      }
      margin-bottom: 10%;
      height: 100%;
      width: 50%;
      margin-top: 25%;
      // margin-left: 7%;
      display: flex;
      flex-direction: column;
      .contact-container {
        padding-left: 8rem;
        @include respond(tab-port) {
          padding-left: 0rem;
        }
      }

      .contact-title {
        @include respond(phone) {
          position: relative;
          margin: 0 auto;
          // margin-left: 25%;
          font-size: 2.2rem;
          text-align: center;
        }
        word-break: break-all;
        position: relative;
        font-size: 3.5rem;
        font-weight: 600;
        letter-spacing: 1.11px;
        color: $color-marine;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        margin-bottom: 49px;

        &::after {
          @include respond(phone) {
            left: 40%;
            width: 20%;
            border-bottom: 4px solid $color-marine;
            bottom: -8px;
          }
          content: "";
          position: absolute;
          width: 10%;
          left: 0;
          bottom: -24px;
          border-bottom: 4px solid $color-marine;
        }
      }

      .contact-time {
        @include respond(phone) {
          position: relative;
          text-align: center;
          margin-top: 30px;
          letter-spacing: 0.33px;
        }
        font-size: 1.4rem;
        span {
          display: block;
        }
      }

      .location-container {
        margin: auto;
        margin-top: 30px;
        @include respond(phone) {
          max-width: 250px;
        }
        display: flex;
        flex-direction: row;
        @include respond(phone) {
        }

        img {
          @include respond(phone) {
            height: 24px;
            margin-right: 54px;
          }
          height: 44px;
          margin-right: 31px;
        }

        .contact-location {
          @include respond(phone) {
            // margin-right: 18%;
          }
          font-size: 1.4rem;
          color: #797778;

          text-decoration: underline;
          z-index: 999;

          span {
            @include respond(phone) {
              display: contents;
            }
            display: block;
          }
        }
      }

      .time-container {
        margin: auto;
        margin-top: 30px;
        @include respond(phone) {
          max-width: 250px;
        }
        display: flex;
        flex-direction: row;
        @include respond(phone) {
        }

        img {
          @include respond(phone) {
            height: 24px;
            margin-right: 54px;
          }
          height: 47px;
          margin-right: 31px;
        }

        .contact-number {
          @include respond(phone) {
            font-size: 1.4rem;
          }
          font-size: 2.2rem;
          span {
            display: block;
          }
        }
      }

      .mail-container {
        margin: auto;
        margin-top: 30px;
        @include respond(phone) {
          max-width: 250px;
        }
        display: flex;
        flex-direction: row;

        img {
          @include respond(phone) {
            height: 17px;
            margin-right: 54px;
          }
          height: 26px;
          margin-right: 31px;
        }

        .contact-mail {
          @include respond(phone) {
            font-size: 1.4rem;
          }
          font-size: 2.2rem;

          text-decoration: underline;
        }
      }
    }

    .contact-form {
      @include respond(phone) {
        position: relative;
        width: 90%;
        z-index: 49;
        // right: 5%;
        margin-top: 5px;
        margin-right: 0rem;
      }
      position: absolute;
      width: 47%;
      background-color: white;
      right: 0;
      height: auto;
      margin-top: 375px;
      z-index: 2;
      margin-right: 8rem;
      box-shadow: 1px -1px 56px 0 rgba(6, 33, 48, 0.24);

      .form-title {
        @include respond(phone) {
          font-size: 2rem;
        }
        text-align: center;
        margin-top: 8%;
        font-size: 3.3rem;
        color: $color-marine;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.1rem;
      }

      .input-style {
        margin-left: 15%;
        margin-top: 51px;
        order: solid 1.5px #797778;
        height: 80px;
        width: 76%;
        font-size: 2.4rem;
        padding-left: 4%;
        // outline: 3px solid $color-dirty-blue;
        &::placeholder {
          font-size: 1.2rem;
        }
      }
      .input-style:focus {
        outline: 3px solid $color-dirty-blue;
      }

      .text-message {
        @include respond(phone) {
          height: 14px;
        }
        position: relative;
        margin-left: 15%;
        margin-top: 51px;
        order: solid 1.5px #797778;
        height: 240px;
        width: 76%;
        font-size: 2.4rem;
        padding-left: 4%;
        padding-top: 21px;
        font-weight: normal;

        &::placeholder {
          position: absolute;
          font-size: 2.4rem;
        }
      }

      .button-style {
        width: 76%;
        margin-left: 15%;
        height: 72px;
        margin-top: 65px;
        margin-bottom: 76px;
        background-color: $color-dirty-blue;
        font-size: 24px;
        font-weight: bold;
        &:hover {
          background-color: darken($primary, 1.5%) !important;
          border-color: darken($primary, 1.5%) !important;
          color: white;
        }
      }
      .button--isi {
        @include respond(phone) {
          height: 42px;
          font-size: 1.6rem;
          margin-left: 10%;
          margin-top: 32px;
          margin-bottom: 36px;
        }
        padding: 0;
        width: 76%;
        margin-left: 15%;
        margin-bottom: 76px;
        margin-top: 65px;
        font-size: 24px;
        height: 72px;
      }
    }
  }

  .footer-div {
    background-color: $color-marine;
    // @include overlay-mesh-grid($color-marine);
    position: relative;
    width: 100%;
    height: 120vh;
    font-size: 1.4rem;
    color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond(phone) {
      height: 100vh;
      margin-top: -80vh;
    }
  }

  .select-box {
    @include respond(phone) {
      width: 76%;
      margin-left: 10%;
      margin-top: 24px;
      margin-right: 10%;
    }
    display: flex;
    width: 400px;
    flex-direction: column;
    margin-left: 15%;
    margin-top: 51px;
    width: 76%;
  }

  .select-box .options-container {
    // position: absolute;
    background: $color-dirty-blue;
    color: #f5f6fa;
    max-height: 0;
    width: 100%;
    z-index: 99;
    opacity: 0;
    transition: all 0.1s;
    border-radius: 0px;
    overflow: hidden;

    order: 1;
  }

  .selected {
    // @include respond(phone) {
    //   height: 42px;
    // }
    border-radius: 0px;
    border: 2px solid #aaa;
    position: relative;
    height: 80px;
    color: #bbbbbb;

    order: 0;

    &::after {
      @include respond(phone) {
        height: 21px;
        width: 12px;
        top: 20px;
      }
      content: "";
      background: url("../../img//contact/arrow.png");
      background-size: contain;
      background-repeat: no-repeat;

      position: absolute;
      height: 25px;
      width: 24px;
      right: 31px;
      top: 31px;

      transition: all 0.4s;
    }
  }

  .selected.active {
    background-color: $color-dirty-blue;
    color: white;
    border-color: $color-dirty-blue;
  }

  .select-box .options-container.active {
    max-height: 240px;
    opacity: 1;
    overflow-y: scroll;
    &::after {
      content: "";
    }
  }

  .select-box .options-container.active + .selected::after {
    content: "";
    background: url("../../img//contact/white-arrow.png");
    background-size: contain;
    background-repeat: no-repeat;

    position: absolute;
    height: 25px;
    width: 24px;
    right: 31px;
    top: 31px;
  }

  .select-box .options-container::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    border-radius: 0 8px 8px 0;
  }

  .select-box .options-container::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0 8px 8px 0;
  }

  .select-box .option,
  .selected {
    @include respond(phone) {
      height: 42px;
      font-size: 1.4rem;
    }
    padding: 0.8em 0.7em;
    cursor: pointer;
    height: 80px;
    font-size: 24px;
    width: auto;
    // background-color: red;
  }

  .select-box .option:hover {
    background-color: rgba(9, 49, 72, 0.24);
  }

  .select-box label {
    cursor: pointer;
  }

  .select-box .option .radio {
    display: none;
  }
}

.upload-div {
  @include respond(phone) {
    margin-left: 10%;
    margin-bottom: 80px;
  }
  position: relative;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 16%;
  margin-top: 65px;
  margin-bottom: 65px;

  input[type="file"] {
    height: 70px;
    position: absolute;
    top: 5px;
    left: 5px;
    opacity: 0;
    z-index: 10000;
    cursor: pointer;
  }

  input[type="file"]::-webkit-file-upload-button {
    height: 70px;
    position: absolute;
    top: 5px;
    left: 5px;
    opacity: 0;
    z-index: 10000;
    cursor: pointer;
  }

  .upload-button {
    cursor: pointer;
    @include respond(phone) {
      height: 48px;
      width: 48px;
      border: 1.5px solid $color-dirty-blue;
    }
    box-shadow: 4px 8px 16px 0 rgba(46, 102, 125, 0.24);
    position: relative;
    border: 2px solid $color-dirty-blue;
    height: 80px;
    width: 80px;
    background-color: transparent;

    border-radius: 50%;

    img {
      @include respond(phone) {
        height: 35px;
        width: 35px;
      }
      width: 52px;
      height: 52px;
      margin-left: 4%;
      margin-top: 5px;
    }
  }

  .upload-title {
    @include respond(phone) {
      font-size: 16px;
      font-weight: 500px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.44px;
      padding-top: 10px;
    }

    padding-top: 20px;
    margin-left: 5%;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #797778;
  }
}

///////////////////////chisato
