@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint==phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    }

    //600px
  }

  @if $breakpoint==tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    }

    //900px
  }

  @if $breakpoint==tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    }

    //1200px
  }

  @if $breakpoint==big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    }

    //1800
  }
}

@mixin circleButton($size) {
  line-height: $size;
  text-align: center;
  width: $size;
  height: $size;
  // border: 2px dashed $color-white;
  border-radius: 50%;
  color: $color-white;
  cursor: pointer;

  &:hover {
    // border: 2px solid $color-white;
    border-radius: 50%;
    border-style: solid;
    background-color: rgba(255, 255, 255, 0.16);
  }
}

// @mixin overlay-mesh-grid($color) {
//     height: 100%;
//     width: 100%;

// }
@mixin overlay-grid($color) {
  height: 100%;
  width: 100%;
  background-image: url("../../img//mesh-grid/mesh-grid.png"),
    linear-gradient(to right, $color, $color);
  background-size: 100%;
  background-repeat: repeat;
}

@mixin heading-font {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@mixin section-header {
  &-header {
    font-size: 7rem;
    position: relative;
    width: 70%;
    padding: 8rem 8rem;

    @include respond(tab-port) {
      width: auto;
      padding: 5rem 4rem;
    }

    @include respond(phone) {
      padding: 2.5rem;
      margin-left: 18px;
    }
  }
}

@mixin overlay-mesh-grid($opacity: 0.15) {
  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("../../img//mesh-grid/mesh-grid.png");
    background-size: cover;
    background-repeat: repeat;
    opacity: $opacity;
    @content;
  }
}

@mixin serif-font {
  font-family: "Droid Serif", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@mixin SetDescriptionMargins(
  $TopMargin,
  $RightMargin,
  $BottomMargin,
  $LeftMargin
) {
  width: 900px;
  height: auto;
  margin: $TopMargin $RightMargin $BottomMargin $LeftMargin;
}

@mixin button-isi($background-color, $text-color) {
  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 100%;
    margin: -15px 0 0 1px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $background-color;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale3d(1, 2, 1);
    transform: scale3d(1, 2, 1);

    -webkit-transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
    -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
    transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
  }

  & > span {
    vertical-align: middle;
    padding-left: 0.75em;
  }

  &:hover {
    transition: color 1s;
    color: $text-color;
  }
}
