.description {
  height: auto;
  overflow: hidden;
}

.desc-container {
  // width: auto;
  // height: auto;

  padding: 10rem;
  // padding-top: 4rem;
  // margin-right: 11.5%;
  // margin-left: 15.6%;
  // margin-bottom: 4rem;

  @include respond(phone) {
    display: flex;
    flex-direction: column;

    padding: 5rem 2rem;
  }
}

.first-textbox {
  height: auto;
  width: auto;
  top: 0;
  font-size: 2em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;

  @include respond(phone) {
    font-size: 12px;
    max-width: 325px;
    align-self: center;
    // margin-left: 25px;
    // margin-right: 25px;
  }
}

.second-textbox {
  height: auto;
  width: auto;
  margin-top: 75px;
  margin-left: 20%;
  font-size: 2em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;

  @include respond(tab-port) {
    height: 100px;
    width: auto;
    margin-left: 0;
    margin-top: 25px;
  }

  @include respond(phone) {
    font-size: 12px;
    max-width: 325px;
    align-self: center;
  }
}

.third-textbox {
  height: auto;
  width: auto;
  margin-top: 75px;
  margin-left: 10%;
  font-size: 2em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;

  @include respond(tab-port) {
    height: 100px;
    width: auto;
    margin-left: 0;
    margin-top: 25px;
  }

  @include respond(phone) {
    font-size: 12px;
    max-width: 325px;
    align-self: center;
  }
}

.fourth-textbox {
  height: auto;
  width: auto;

  margin-top: 75px;
  font-size: 2em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;

  @include respond(phone) {
    font-size: 12px;
    margin-top: 25px;
    max-width: 325px;
    align-self: center;
  }
}

.text-style-1 {
  // font-family: "Avenir Next";
  font-weight: 600;
}

.text-style-2 {
  font-weight: 500;
}
