// Core variables and mixins
@import "./abstracts/variables.scss";
@import "./abstracts/mixins.scss";

// // Global CSS
// @import "base/page.scss";

// // Components
@import "./components/buttons.scss";
@import "./components/navbar.scss";
@import "./components/headnav.scss";

// // Layout
@import "./layout/masthead.scss";
@import "./layout/description.scss";
@import "./layout/mission";
// @import "./layout/portfolio.scss";
@import "./layout/vision.scss";
// @import "layout/team.scss";
// @import "layout/contact.scss";
// @import "layout/footer.scss";

.path-div {

    min-height: 20px;
    width: 300px;
    position: absolute;
    overflow: hidden;
    top: 95vh;
    left: 30vw;
    z-index: 1;
    opacity: 1;
    // background-image: url('../../img/path@2x.png');
    // background-repeat: no-repeat;
    // background-position: top;
    // background-size: contain;

    img {
        width: 100%;
    }

}