// Styling for the masthead
header.masthead {
  position: relative;
  flex-basis: auto;
  text-align: center;
  color: white;
  background-image: url("../../img/matias.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  @include overlay-mesh-grid(1);

  @include respond(tab-port) {
    // height: 667px;
    height: 100vh;
  }

  .intro-text {
    position: absolute;
    top: 40%;
    // padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    // height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: center;

    @include respond(tab-port) {
      // height: 667px;
      top: 40%;
      justify-content: center;
    }
  }
}

.container_header {
}

@media (min-width: 900px) {
  header.masthead {
    flex-basis: auto;

    .intro-text {
      // padding-top: 300px;
      // padding-bottom: 200px;

      .intro-lead-in {
        font-size: 40px;
        font-style: italic;
        line-height: 40px;
        margin-bottom: 25px;
        @include serif-font;
      }

      .intro-heading {
        font-size: 75px;
        font-weight: 700;
        line-height: 75px;
        margin-bottom: 50px;
        @include heading-font;
      }
    }

    .container1 {
      flex-basis: auto;
      display: flex;
      flex-direction: row;
      height: 100%;
    }

    .flexcontainer {
      display: flex;
      flex-direction: column;
      background-color: transparent;
      justify-content: center;
      margin-top: 130px;
    }

    .flexcontainer > a {
      font-size: 12px;
      color: $color-marine;
      width: 105px;
      transform: rotateZ(-90deg);
      margin: 50px;
      height: auto;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      cursor: pointer;
    }
  }
}

.flexcontainer > a {
  font-size: 13px;
  color: $color-ugly-blue;
  width: 105px;
  margin: 50px;
  height: auto;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
}

.container1 {
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  @include overlay-mesh-grid(0.65);
}

.flexcontainer {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  justify-content: center;
  margin-top: 130px;
}

// .actives {
//   border-bottom: 2.5px solid $color-ugly-blue;
