.carousel-indicators li {
  background-color: $color-dirty-blue;
}

span.carousel-control-prev-icon {
  height: 3rem;
  background-image: url("../img/back.svg");
  transform: rotate(180deg);
}

span.carousel-control-next-icon {
  height: 3rem;
  background-image: url("../img/back.svg");
}

#vision-carousel {
  span.carousel-control-prev-icon {
    background-image: url("../img/back_white/back@2x.png");
    height: 3rem;
  }

  span.carousel-control-next-icon {
    background-image: url("../img/back_white/back@2x.png");
    height: 3rem;
  }

  .carousel-control-next {
    position: absolute;
    height: 1.5rem;
    top: auto;
    bottom: -6rem;
    right: 47%;
    height: 1.5rem;
    width: auto;

    @include respond(phone) {
      height: 1rem;
      right: 40%;
      bottom: 30rem;
    }
  }

  .carousel-control-prev {
    height: 1.5rem;
    top: auto;
    bottom: -6rem;
    left: 47%;
    width: auto;

    @include respond(phone) {
      height: 1rem;
      left: 40%;
      bottom: 30rem;
    }
  }
}

.section-news {
  span.carousel-control-prev-icon {
    height: 3rem;
    background-image: url("../img/back_white/back@2x.png");
    transform: rotate(180deg);
  }

  span.carousel-control-next-icon {
    height: 3rem;
    background-image: url("../img/back_white/back@2x.png");
  }

  .carousel-control-next {
    height: 50px;
    left: 0;
    top: 15%;
    width: 5%;
    right: none;
  }

  .active {
    background-color: transparent !important;
  }

  .carousel-control-prev {
    height: 50px;
    top: 0;
    width: 5%;
  }

  .active::after {
    content: "";
  }

  .news {
    .carousel-item.active {
      .item__third {
      }
    }
  }
}

.section-clients {
  .carousel-control-next,
  .carousel-control-prev {
    opacity: 0.1;
    width: 11%;
    align-items: flex-start;

    top: 40%;

    &:hover {
      opacity: 0.9;
    }
  }

  .carousel-indicators {
    left: 5rem;

    @include respond(phone) {
      left: 2rem;
      bottom: -5vh;
    }
  }

  .carousel-item.active {
    background-color: transparent !important;
  }

  .active::after {
    content: "";
  }

  li {
    background-color: $color-grey;
  }

  li.active {
    width: 30px;
    height: 3px;
    background-color: $color-dirty-blue;
  }

  @include respond(phone) {
    .carousel-control-next,
    .carousel-control-prev {
      top: 12vh;
      width: 5%;
    }
  }
}

.section-management {
  span.carousel-control-prev-icon {
    height: 3rem;
    background-image: url("../img/back_white/back@2x.png");
    transform: rotate(180deg);

    @include respond(phone) {
      height: 2.2rem;
      width: 1.2rem;
    }
  }

  span.carousel-control-next-icon {
    height: 3rem;
    background-image: url("../img/back_white/back@2x.png");

    @include respond(phone) {
      height: 2.2rem;
      width: 1.2rem;
    }
  }

  .active::after {
    content: "";
  }
}

.row:before,
.row:after {
  display: none !important;
}
