#vision {
}

.Vision-title {
  margin-bottom: 4rem;
  max-width: 538px;
  font-size: 6rem;
  font-weight: bold;
  color: $color-marine;
  position: relative;
  letter-spacing: 2px;

  @include respond(tab-port) {
    font-size: 50px;
  }

  @include respond(phone) {
    font-size: 26px;
    margin-bottom: 30px;
  }
}

.Vision-title:after {
  content: "";
  margin: auto 0;
  position: absolute;
  width: 80px;
  left: 0;
  bottom: 5px;
  border-bottom: 5px solid $color-marine;

  @media screen and (max-width: 437px) {
    border-bottom: 1px solid $color-marine;
    /* This creates the border. Replace black with whatever color you want. */
  }
}

.To-form-long-term-m {
  height: auto;
  max-width: 1050px;
  font-size: 2.2rem;
  font-weight: 400;
  font-stretch: normal;

  font-style: normal;
  line-height: 40px;
  letter-spacing: 0;
  color: black;
  margin-bottom: 10rem;

  @include respond(tab-land) {
    font-size: 20px;
    line-height: 30px;
  }

  @include respond(tab-port) {
    font-size: 15px;
    line-height: 25px;
  }

  @include respond(phone) {
    max-width: 328px;
    font-size: 11px;
    line-height: 17px;
  }
}

.vision-content {
  align-items: flex-start;
  margin-left: 3.2rem;

  height: 700px;
  width: auto;
  background-color: transparent;
  display: flex;
  margin-top: 17%;

  flex-direction: column;

  @include respond(phone) {
    height: 280px;
    margin-top: 70px;
    font-size: 8px;
    margin-left: 1rem;
  }

  // @media only screen and (max-width: 375px) {

  // }
}

.vision-typo {
  font-size: 15px;
  font-weight: 400;

  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $color-white;

  @include respond(phone) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 11; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 6.5px;
    letter-spacing: 0;
    padding: 0px 10px 0px 10px;
  }
}

.vision-typo2 {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  width: 85%;

  @include respond(tab-port) {
    font-size: 12px;
  }

  @include respond(phone) {
    font-size: 8px;
    padding-left: 10px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 8px;
  }
}

.vision-container1 {
  background-color: transparent;
  height: auto;
  width: auto;
  display: flex;
}

.image-view {
  background-image: linear-gradient(
    to bottom,
    rgba(9, 49, 72, 0),
    rgba(9, 49, 72, 0.5) 87%
  );
  background-size: cover;
  width: 42%;
  height: 600px;
  border: 0;
  // transform: translate(0px, -20px);
  margin-bottom: 4%;
  box-shadow: 0 24px 50px 0 rgba(5, 25, 37, 0.32);
  // background-blend-mode: soft-light;

  object-fit: cover;
  object-position: center;
  @include respond(phone) {
    width: 55%;
    height: 280px;
    // margin-bottom: 2px;
  }

  // @media only screen and (max-width: 375px) {
  //   width: 52%;
  //   height: 240px;
  // }
}

.vision-comment {
  font-size: 22px;
  color: white;
  max-width: 290px;
  display: flex;
  margin-top: 20%;
  flex-direction: column;
  background-color: transparent;
  margin-bottom: 2rem;

  @include respond(phone) {
    max-width: 126px;
    margin-top: 30%;
    margin-bottom: 1.5rem;
    font-size: 11px;
  }
}

.main_container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $color-marine;
  // height: 1250px;
  @include overlay-mesh-grid();

  // @include respond(phone) {
  //   height: 700px;
  // }

  .main_div {
    // position: absolute;
    // z-index: 1;
    // bottom: 12%;
    position: relative;
    margin-top: -8rem;
    width: 100%;
    margin-bottom: 10rem;

    @include respond(phone) {
      margin-top: -8rem;
      margin-bottom: -20rem;
    }
    span.carousel-control-next-icon {
      @include respond(phone) {
        position: absolute;
        top: 1rem;
      }
    }
    span.carousel-control-prev-icon {
      @include respond(phone) {
        position: absolute;
        top: 1rem;
        left: 10px;
      }
    }

    .show-other {
      position: relative;
      overflow: hidden;
      height: 600px;
      background-color: transparent;
      margin-left: 200px;
      color: $color-marine;

      @include overlay-mesh-grid() {
        background-position: bottom;
      }

      @include respond(phone) {
        margin-left: 24px;
      }

      @include respond(tab-port) {
        margin-left: 24px;
      }

      $fragment_width: 15%;
      $item_third_width: 33%;

      .carousel-indicators {
        margin-right: $fragment_width;
        margin-left: $fragment_width;
        background-color: transparent;
      }

      .carousel-inner {
        width: (100% - 2 * $fragment_width) * 3;
        left: 7 * $fragment_width - 100%;
        color: $color-marine;
        // transition: opacity 0.5s;
        // @include overlay-mesh-grid() {
        //   background-position: bottom;
        // }
      }

      .carousel-item {
        // opacity: 0;
        background-color: transparent;
      }
      .carousel-item.active {
        // opacity: 1;
        background-color: transparent;
      }

      .carousel-item-next:not(.carousel-item-left),
      .carousel-item-right.active {
        -webkit-transform: translate3d($item_third_width, 0, 0);
        transform: translate3d($item_third_width, 0, 0);
      }

      .carousel-item-prev:not(.carousel-item-right),
      .carousel-item-left.active {
        -webkit-transform: translate3d(-2 * $item_third_width, 0, 0);
        transform: translate3d(-1 * $item_third_width, 0, 0);
      }

      .item__third {
        float: left;
        position: relative;
        /* captions can now be added */
        width: $item_third_width;
        background-color: transparent;
      }
    }
  }

  .second_div {
    height: 90%;

    position: absolute;
    bottom: 0px;
    width: 100%;

    @include overlay-mesh-grid() {
      background-position: bottom;
    }

    background-color: $color-marine;
  }
}

.testimonial {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  // width: 84px;
  // height: 24px;
  background-color: transparent;
  // color: white;
  left: 40%;
}

.testimonial-square {
  width: 84px;
  height: 24px;
  background-color: $color-dirty-blue;
  margin-right: 8%;

  @include respond(phone) {
    width: 56px;
    height: 16px;
  }
}

.testimonial-name {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: $color-dirty-blue;

  @include respond(phone) {
    font-size: 9px;
  }
}
