.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem;
  @include respond(phone) {
    margin-bottom: 2px !important;
  }
}

.u-margin-bottom-medium {
  margin-bottom: 18px !important;

  @include respond(tab-port) {
    margin-bottom: 16px !important;
  }
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;

  @include respond(tab-port) {
    margin-bottom: 5rem !important;
  }
}

.u-margin-bottom-extra-big {
  margin-bottom: 150px !important;

  @include respond(tab-port) {
    margin-bottom: 8rem !important;
  }
}

.u-padding {
  padding-left: 5rem;

  @include respond(tab-port) {
    padding-left: 2rem !important;
  }

  @include respond(phone) {
    padding-left: 0rem !important;
  }
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

.u-marine {
  color: $color-marine !important;
  opacity: 1 !important;
}

.u-bold {
  font-weight: bold !important;
}

.u-bold-600 {
  font-weight: 600 !important;
}

.u-disable {
  &:hover {
    text-decoration: none;
    cursor: default;
    color: $color-grey !important;
    opacity: 0.6 !important;
  }
}

.u-no-transform {
  text-transform: none !important;
}
.u-capitalize {
  text-transform: capitalize !important;
}
.u-uppercase {
  text-transform: uppercase !important;
}
