.meet-the-team {
  .section-about-team {
    height: 100vh;
    width: 100vw;
    position: relative;
    @include respond(phone) {
      height: 667px;
    }

    .flexcontainer {
      margin-top: 0px;
      position: absolute;
      bottom: 10%;
      opacity: 1;
      justify-content: flex-end;
      padding-bottom: 5rem;
    }

    .header-team {
      position: relative;
      top: 0;
      // padding-top: 33%;
      background-image: url(../../img/group-9.jpg);
      // opacity: 0.6;
      // height: 800px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      // background-repeat-x: repeat;
      background-position: center;
      height: 100vh;
      width: 100vw;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      @include overlay-mesh-grid(0.6);

      @include respond(tab-port) {
        // height: 667px;
        padding-top: 10rem;
        align-items: center;
      }

      @include respond(phone) {
        background-size: 100vw 100vh;
      }

      &__text-box {
        position: absolute;
        top: 40%;
        width: 40%;
        // top: 35%;
        // left: 30%;
        opacity: 1;
        text-align: center;

        @include respond(tab-port) {
          top: 40%;
          width: auto;
        }

        @include respond(phone) {
          top: 40%;
          max-width: 315px;
        }

        h2 {
          font-size: 7.5rem;
          font-weight: bold;
          color: $color-marine;

          @include respond(phone) {
            font-size: 5rem;
          }
        }

        // left: 5%;
      }
    }
  }

  .section-management {
    position: relative;
    // overflow-y: visible;
    background-color: $color-marine;
    padding: 10rem 0 15rem 0;

    @include overlay-mesh-grid(0.2);

    .management-header {
      padding-left: 20rem;
      position: relative;
      z-index: 2;

      .heading-secondary {
        font-size: 6.4rem;
        font-weight: 600;
        text-transform: none;
        letter-spacing: 2px;
        position: relative;

        &::after {
          content: " ";
          /* height: 50px; */
          width: 80px;
          position: absolute;
          bottom: -15px;
          left: 0;
          color: white;
          border-top: 8px solid white;

          @include respond(tab-port) {
            bottom: -5px;
            border-top-width: 3px;
          }

          @include respond(phone) {
            bottom: -4px;
            border-top-width: 2px;
            width: 20%;
          }
        }
      }

      @include respond(phone) {
        padding-left: 5rem;

        .heading-secondary {
          font-size: 26px;
        }
      }
    }

    .updated_carousel-control.carousel-control-prev {
      justify-content: flex-end;
      left: 40vw;
    }

    .updated_carousel-control.carousel-control-next {
      justify-content: flex-start;
      right: 40vw;
    }

    .updated_carousel-control {
      align-items: flex-end;
      width: 5%;
      height: 25px;
      top: inherit;
      bottom: 2rem;
      padding-bottom: 5rem;
    }

    .active {
      background: none !important;
    }

    @include overlay-mesh-grid();
    $fragment_width: 30%;
    $fragment_width-mobile: 20%;
    $item_third_width: 33%;

    .multi-item-carousel {
      overflow: hidden;
      position: relative;
      z-index: 2;

      .carousel-indicators {
        margin-right: $fragment_width;
        margin-left: $fragment_width;
      }

      .container {
        background-color: transparent;
      }

      .carousel-control-prev,
      .carousel-control-next {
        // width: 15%;
        // justify-content: flex-end;
        // align-items: flex-end;
        // margin-bottom: -25px;
        display: none;
        // left: 8rem;
        // z-index: 11;
        /* .carousel-caption has z-index 10 */
      }

      .carousel-inner {
        width: (100% - 2 * $fragment_width) * 3;
        left: 3 * $fragment_width - 100%;

        @include respond(phone) {
          margin-top: 34px;
          width: (100% - 2 * $fragment_width-mobile) * 3;
          left: 2 * $fragment_width-mobile - 100%;
        }
      }

      .carousel-item-next:not(.carousel-item-left),
      .carousel-item-right.active {
        -webkit-transform: translate3d(-1 * $item_third_width, 0, 0);
        transform: translate3d(-1 * $item_third_width, 0, 0);
      }

      .carousel-item-prev:not(.carousel-item-right),
      .carousel-item-left.active {
        -webkit-transform: translate3d(1 * $item_third_width, 0, 0);
        transform: translate3d(1 * $item_third_width, 0, 0);
      }

      .item__third {
        float: right;
        position: relative;
        /* captions can now be added */
        height: 85vh;
        width: $item_third_width;

        .container {
          height: 100%;

          .row {
            height: 100%;
            justify-content: center;
          }
        }
      }

      @include respond(phone) {
        left: 31px;

        .item__third {
          height: 269px;
          width: 33%;
        }
      }
    }
  }

  .section-crew {
    position: relative;

    // overflow-y: visible;
    background-color: $color-white;
    padding: 10rem 0 15rem 0;

    // @include overlay-mesh-grid(0.7) {
    //   background-size: 100%;

    //   @include respond(phone) {
    //     background-size: 100%;
    //   }
    // }
    .crew-container {
      padding: 0 20rem;
      position: relative;
      z-index: 100;

      @include respond(phone) {
        padding: 0 5rem;
      }
    }
    .u-margin-bottom-extra-big {
      @include respond(phone) {
        margin-bottom: 4rem !important;
      }
    }

    &--item {
      padding-right: 50px;
      // max-width: 297px;
      // padding: 0;
      width: 100%;

      @include respond(tab-port) {
        display: flex;
        justify-content: center;
        padding-right: 0;
      }
    }

    &-header {
      @include respond(phone) {
        bottom: 33px;
      }

      // padding-left: 15rem;
      position: relative;
      z-index: 2;

      .heading-secondary {
        font-size: 6.4rem;
        font-weight: 600;
        color: $color-marine;
        text-transform: none;
        letter-spacing: 2px;
        position: relative;

        &::after {
          content: " ";
          /* height: 50px; */
          width: 80px;
          position: absolute;
          bottom: -15px;
          left: 0;
          color: $color-marine;
          border-top: 8px solid $color-marine;

          @include respond(tab-port) {
            bottom: -5px;
            border-top-width: 3px;
          }

          @include respond(phone) {
            width: 25%;
            bottom: -4px;
            border-top: 2px solid $color-marine;
          }
        }
      }

      @include respond(phone) {
        // padding-left: 3rem;

        .heading-secondary {
          font-size: 26px;
        }
      }
    }

    .container-fluid {
      width: 100%;
      padding: 0;
      margin: 0px;

      @include respond(tab-port) {
        padding: 0 5rem;
      }
    }
  }
}
