/* Shylock (by @benhanks040888) */

.menu__list {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
}

.menu__item {
    display: block;
    margin: 0 0;
}

.menu__link {
    font-size: 1.05em;
    font-weight: bold;
    display: block;
    padding: 1em;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.menu__link:hover,
.menu__link:focus {
    outline: none;
}

.menu--shylock .menu__link {
    text-decoration: none;
    position: relative;
    margin: 0 1em;
    padding-right: 0;
    padding-left: 0;
    color: $color-marine;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

.menu--shylock .menu__item--current .menu__link,
.menu--shylock .menu__item--current .menu__link:hover,
.menu--shylock .menu__item--current .menu__link:focus {
    color: $color-ugly-blue;
}

.menu--shylock .menu__item--current .menu__link::after,
.menu--shylock .menu__item--current .menu__link::before {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}

.menu--shylock .menu__item--current .menu__link::before {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.menu--shylock .menu__link:hover,
.menu--shylock .menu__link:focus {
    color: $color-ugly-blue;
}

.menu--shylock .menu__link:hover::before,
.menu--shylock .menu__link:focus::before {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.menu--shylock .menu__link::before,
.menu--shylock .menu__link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 70%;
    height: 3px;
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
    -webkit-transform-origin: center left;
    transform-origin: center left;
    -webkit-transition: transform 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
    transition: transform 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.menu--shylock .menu__link::before {
    background: $color-ugly-blue;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.menu--shylock .menu__link::after {
    background: $color-ugly-blue;
}