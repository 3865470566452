.project-card {
  padding-left: 14px !important;
  padding-right: 14px !important;
  .project-div {
    height: 100%;
    width: 100%;
    background-color: $color-marine;
    background-size: cover;
    margin-bottom: 2.8rem;
    padding: 2.5rem;
    padding-bottom: 4.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: white;

    box-shadow: 0 24px 48px 0 rgba(3, 21, 31, 0.24);

    @include respond(phone) {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 25px;
    }

    .project-data {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .project-name {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-width: 75%;

        .type {
          font-size: 2.2rem;
          font-weight: 300;
          flex: 1;
          text-transform: capitalize;

          @include respond(phone) {
            font-size: 1.1rem;
            font-weight: 500;
          }
        }

        .name {
          font-size: 3.2rem;
          font-weight: 400;
          flex: 1;
          line-height: 1.4;
          text-transform: uppercase;
          text-overflow: ellipsis;
          overflow: hidden;

          @include respond(phone) {
            font-size: 18px;
            font-weight: 500;
          }
        }
      }

      .svg {
        // position: absolute;
        // top: 50%;
        // transform: translateY(-50%);
        // right: 2rem;
        // display: block;
        // z-index: 1;
        // @include respond(phone) {
        //   bottom: -3rem;
        //   right: -3rem;
        // }
        position: relative;
        right: 1rem;

        svg {
          width: 70px;
          height: 70px;

          circle {
            cx: 35px;
            cy: 35px;
            r: 30px;
          }

          @include respond(phone) {
            width: 50px;
            height: 50px;

            circle {
              cx: 25px;
              cy: 25px;
              r: 20px;
            }
          }
        }

        .circle-button {
          @include circleButton(60px);

          font-size: 1.5rem;
          background-image: url("../../img/oval/oval@2x.png"),
            url("../../img/back_white/back@2x.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 60px 60px, 12px;
          position: absolute;
          bottom: 0.5rem;
          left: 0.5rem;
          transition: background-color 1s linear;

          &:hover {
            // line-height: 59px;
            border: 0px solid transparent;
            border-radius: 50%;
            border-style: solid;
            background-color: rgba(255, 255, 255, 0.2);
          }

          @include respond(phone) {
            @include circleButton(41px);
            // background-size: 35px 35px, 10px;
            background-size: 40px 40px, 10px;
            font-size: 6px;
            line-height: 59px;
            text-align: center;

            &:hover {
              line-height: normal;
            }
          }
        }
      }

      .anicircle {
        fill: none;
        stroke-width: 3px;
        stroke: $color-white;
        stroke-dasharray: 400 400;
        stroke-dashoffset: 400;
        transition: stroke-dashoffset 2s linear;
        @include respond(phone) {
          stroke-width: 2px;
        }
      }

      .svg:hover .anicircle {
        stroke-dashoffset: 5;
      }
    }

    // &:hover {
    //   cursor: pointer;

    //   .circle-button {
    //     background-image: url("../../img/back_white/back@2x.png");
    //     background-repeat: no-repeat;
    //     background-position: center;
    //     background-size: 10px;
    //     border: 2px solid $color-white;
    //     border-radius: 50%;
    //     border-style: solid;
    //     background-color: rgba(255, 255, 255, 0.16);

    //     @include respond(phone) {
    //       background-size: 10px;
    //     }
    //   }
    // }
  }
}
