@import "abstracts/functions";
@import "abstracts/mixins";
@import "abstracts/variables";
@import "abstracts/inheritance";

@import "base/animations";
@import "base/base";
@import "base/typography";
@import "base/utilities";

@import "components/bg-video";
@import "components/button-isi";
@import "components/button";
@import "components/card";
@import "components/composition";
@import "components/feature-box";
@import "components/form";
@import "components/popup";
@import "components/story";
@import "components/accordion";
@import "components/filter-accordion";
@import "components/project-card";
@import "components/management-team-card";
@import "components/crew-card";
@import "components/news-card";
@import "components/video";
@import "components/menu-shylock";
@import "components/set2";
@import "components/spinner";

@import "layout/footer";
@import "layout/grid";
@import "layout/header";
@import "layout/sidenav";
@import "layout/navigation";
@import "layout/navbar";

@import "pages/home";
@import "pages/about";
@import "pages/about-team";
@import "pages/ceo";
@import "pages/projects";
@import "pages/contact";
@import "pages/single_project";
@import "pages/careers";
@import "pages/news";
@import "pages/article-detail";
@import "pages/partners";
@import "pages/filter-respond";
@import "./bootstrapp.scss";

// @import "pages/single_project";
@import "components/menu-shylock";
@import "./bootstrapp.scss";

a:not([href]) {
  cursor: pointer;
}
