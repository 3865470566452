.project-header {
  overflow: auto;
  .single-container {
    position: relative;
    width: 100%;
    height: 650px;
    @include respond(tab-port) {
      height: 650px;
    }
    @include respond(phone) {
      height: 350px;
    }
    .div-carousel {
      width: 100%;
    }
  }
  .project-description {
    margin-top: -20rem;
    position: relative;
    height: auto;
    width: 100%;
    overflow: hidden;
    background-color: $color-marine;

    @include respond(tab-port) {
      margin-top: -7rem;
    }

    @include overlay-mesh-grid(0.15);
    .description-div {
      position: relative;
      width: 85%;
      margin: auto;
      padding-top: 35rem;
      height: auto;

      @include respond(tab-port) {
        padding-top: 25rem;
        width: 90%;
      }

      @include respond(phone) {
        padding-top: 30rem;
      }

      .description-title {
        position: relative;
        color: $color-dirty-blue;
        font-size: 2.2rem;
        font-weight: 500;
      }
      .description-headline {
        color: white;
        font-size: 4.2rem;
        font-weight: bold;
        margin-top: 2rem;
        line-height: 1.4;
        text-transform: uppercase;
        margin-bottom: 4rem;
        @include respond(phone) {
          font-size: 3.2rem;
          margin-bottom: 2.5rem;
        }
      }
      .description-content {
        font-size: 2rem;
        color: white;
        line-height: 1.54;
        font-weight: 500;
        @include respond(phone) {
          font-size: 1.6em;
          line-height: 1.67;
        }
      }
      .gradient-line-horizontal {
        position: absolute;
        z-index: 4;
        // top: 50%;
        bottom: 3%;
        width: 100%;
        height: 1px;
        background-size: 100% 1px;
        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0) 0%,
          $color-white 50%,
          rgba(0, 0, 0, 0) 100%
        );

        @include respond(phone) {
          bottom: 0;
        }
      }
    }
    .row {
      margin-top: 50px;
      padding: 0 2rem;

      @include respond(tab-port) {
        padding: 0 2rem;
        margin-top: 25px;
      }
      .details-div {
        display: flex;
        flex-direction: column;
        margin-bottom: 64px;
        padding: 0 3rem;

        @include respond(tab-port) {
          margin-bottom: 34px;
        }
        .details1 {
          font-size: 3rem;
          color: white;
          font-weight: 200;
          margin-bottom: 1rem;
          // font-family: sans-serif;
          // font-family: "AvenirNext" !important ;

          @include respond(tab-port) {
            font-size: 1.9em;
            font-weight: 100;
          }
        }
        .details2 {
          font-weight: bold;
          font-size: 3rem;
          color: white;

          @include respond(tab-port) {
            font-size: 2em;
            font-weight: 500;
          }
          img {
            max-width: 100%;
            max-width: 100px;
          }
          span {
            display: block;
          }
        }
        .details3 {
          padding-left: 15%;

          @include respond(tab-port) {
            padding-left: 0px;
          }
        }
      }
    }
    .buttons-div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      margin: 0 auto;
      margin-bottom: 8rem;
      margin-top: 8rem;

      @include respond(tab-port) {
        margin-bottom: 6rem;
        margin-top: 6rem;
        max-width: 800px;
      }
      @include respond(tab-port) {
        max-width: 400px;
      }
      .prev-a {
        @include respond(phone) {
          height: 42px;
          width: 170px;
        }
        position: relative;
        height: 80px;
        width: 380px;
        display: flex;
        flex-direction: row;
        background-color: white;
        &:hover {
          .theimage {
            transform: rotateZ(180deg);
            background-image: url("../../img/singleProject/back-white.png");
          }
        }

        .theimage {
          position: absolute;
          height: 22px;
          width: 12px;
          border-color: transparent;
          background-size: contain;
          background-repeat: no-repeat;
          top: 35%;
          background-image: url("../../img/singleProject/back-blue.png");
          left: 10%;

          @include respond(tab-port) {
            height: 15px;
            width: 10px;
            left: 5%;
          }
        }
        div {
          position: absolute;
          top: 30%;
          font-size: 1.8rem;
          right: 10%;
          // color: $color-dirty-blue;
          letter-spacing: 1px;
          text-transform: uppercase;
          @include respond(tab-port) {
            font-size: 11px;
          }
        }
      }
      .next-a {
        @include respond(phone) {
          height: 42px;
          width: 170px;
          margin-left: 10px;
        }
        position: relative;
        height: 80px;
        width: 380px;
        display: flex;
        flex-direction: row;
        background-color: $color-dirty-blue;
        margin-left: 5%;
        transition: all 1s;
        &:hover {
          .theimage {
            transform: rotateZ(180deg);
            background-image: url("../../img/singleProject/back-blue.png");
          }
        }

        .theimage {
          position: absolute;
          height: 22px;
          width: 12px;
          background-size: contain;
          background-repeat: no-repeat;
          top: 35%;
          left: 85%;
          background-image: url("../../img/singleProject/back-white.png");

          @include respond(tab-port) {
            height: 15px;
            width: 10px;
            left: 90%;
          }
        }
        div {
          position: absolute;
          top: 30%;
          font-size: 1.8rem;
          left: 10%;
          // color: $color-dirty-blue;
          letter-spacing: 1px;
          text-transform: uppercase;
          @include respond(tab-port) {
            font-size: 11px;
          }
        }
      }
    }
  }
}

/////////
.main-div {
  // position: absolute;
  width: 100%;
  margin-top: 25rem;

  left: 0; // bottom: 0;

  @include respond(tab-port) {
    padding-top: 150px;
    margin-top: 5rem;
  }
  @include respond(phone) {
    top: 0;
    padding-top: 15.7rem;
  }
  // overflow-y: visible;
  // padding: 10rem 0 15rem 0;
  .projectsBack {
    margin-bottom: 70px;
    height: auto;
    width: 20%;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    // border: 1px solid grey;
    margin-left: 5%;
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }

    img {
      height: 20px;
      margin-top: auto;
      margin-bottom: auto;
    }
    div {
      font-weight: 500;
      font-size: 1.8em;
      margin-left: 4%;
      color: $color-marine;
      word-wrap: break-word;
      @include respond(phone) {
        margin-left: 16px;
      }
    }

    @include respond(tab-port) {
      width: 50%;
    }
    @include respond(phone) {
      font-size: 1em;
    }
  }
  span.carousel-control-prev-icon {
    height: 2.5rem;
    background-image: url("../../img/back_white/back@2x.png");
    transform: rotate(180deg);
    background-size: contain;
    @include respond(tab-port) {
      height: 2rem;
    }
  }

  span.carousel-control-next-icon {
    height: 2.5rem;
    background-image: url("../../img/back_white/back@2x.png");
    background-size: contain;
    @include respond(tab-port) {
      height: 2rem;
    }
  }

  .carousel-control-next {
    height: 50px;
    right: 38%;
    top: 100%;

    @include respond(tab-port) {
      height: 42px;
      right: 28%;
    }
  }

  .active {
    background-color: transparent !important;
  }
  .active {
    background: none !important;
  }

  .carousel-control-prev {
    height: 50px;
    left: 38%;
    top: 100%;
    @include respond(tab-port) {
      height: 42px;
      left: 28%;
    }
  }
  .owl-nav {
    display: block !important;
  }
  .slider-counter {
    position: absolute;
    height: auto;
    right: 51%;
    width: fit-content;
    background-color: transparent;
    color: white;
    z-index: 9999;
    font-size: 2.8em;

    @include respond(tab-port) {
      right: 54%;
    }
  }
  .slider-counter2 {
    position: absolute;
    height: auto;
    right: 48%;

    width: fit-content;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.5);
    z-index: 9999;
    font-size: 2em;
    padding-top: 9px;
    @include respond(tab-port) {
      right: 45%;
    }
  }

  .img_box {
    position: relative;
    height: 500px;
    width: 700px;
    // position: absolute;
    // left: 50%;
    @include respond(phone) {
      height: 65vw;
      width: 90vw;
    }
    img {
      height: 500px;
      width: 700px;
      // transform: translateX(-50%);

      @include respond(tab-port) {
        height: 65vw;
        width: 90vw;
      }
      @include respond(phone) {
        height: 65vw;
        width: 90vw;
      }
    }
  }
  .owl-carousel {
    padding-bottom: 50px;
    @include respond(phone) {
      padding-bottom: 18px;
    }
  }
  .owl-carousel .owl-item.active.center {
    transform: scale(1, 1);
    z-index: 10;
  }
  .owl-carousel .owl-item {
    display: flex;
    justify-content: center;
    position: relative;
    transform: scale(0.7, 0.7);

    transition: all 0.5s;
    &::after {
      content: "";
    }
  }

  .owl-dots {
    counter-reset: slides-num; /* Initialize counter. */
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: 15px;

    &:after {
      content: counter(
        slides-num
      ); /* get total number of items - more info on http://www.sitepoint.com/a-little-known-way-to-replace-some-scripts-with-css-counters/ */
      display: inline-block;
      font-size: 20px;
      font-weight: 700;
      vertical-align: middle;
      padding-left: 5px;
    }
  }
  .big.pre {
    transform: translateX(-40%) !important;
    @include respond(phone) {
      transform: translateX(-60%) !important;
    }
  }

  .big.next {
    transform: translateX(40%) !important;
    @include respond(phone) {
      transform: translateX(60%) !important;
    }
  }
  .img_box_size {
    height: 500px;
    width: 700px;
    // position: absolute;
    // left: 50%;
    @include respond(phone) {
      height: 45vw;
      width: 65vw;
    }
  }
  .center {
    // left: 30px;
    // transform: translateX(-50%) !important;
  }
}

.col-xs-6 {
  @include respond(phone) {
    width: 50% !important;
  }
}

.owl-dots {
  display: none !important;
}
