/* Isi */
.button {
  display: block;
  padding: 2rem 3.5rem;
  border: none;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

.button:focus {
  outline: none;
}

.button > span {
  vertical-align: middle;
}

.button--text-thick {
  font-weight: 600;
}

.button--text-upper {
  letter-spacing: 2px;
  text-transform: uppercase;
}

.button--size-s {
  font-size: 12px;
}

.button--isi {
  padding: 1.2em 2em;
  font-weight: 500;

  @include respond(tab-port) {
    font-weight: 500;
    letter-spacing: 1.2px;
  }

  overflow: hidden;
}

.button--isi {
  &--dirty-blue {
    transition: color 1s;
    color: $color-white;
    background: $color-dirty-blue;
    border: 1px solid $color-dirty-blue;

    @include button-isi($color-white, $color-dirty-blue);
  }

  &--white {
    background: $color-white;
    transition: color 1s;
    color: $color-dirty-blue;
    border: 1px solid $color-dirty-blue;

    @include button-isi($color-dirty-blue, $color-white);
  }

  &--marine {
    background: $color-marine;
    transition: color 1s;
    color: $color-white;
    border: 1px solid $color-dirty-blue;

    @include button-isi($color-dirty-blue, $color-white);
  }
}

.button--isi .button__icon {
  vertical-align: middle;
}

.button--isi > span {
  vertical-align: middle;
  padding-left: 0.75em;
  font-weight: 500;

  @include respond(tab-port) {
    font-weight: 400;
  }
}

.button--isi {
  &:hover::before {
    -webkit-transform: scale3d(21, 9, 1);
    transform: scale3d(21, 9, 1);
  }
}
