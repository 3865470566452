.section-article {
  overflow: auto;
  .article-header {
    position: relative;
    overflow: auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    background-color: white;

    // @include overlay-mesh-grid() {
    //   opacity: 0.5;
    //   // background-size: auto;
    // }
    .article-main {
      position: relative;
      margin-top: 30rem;

      @include respond(tab-port) {
        margin-top: 20rem;
      }
      .social-logos {
        position: absolute;
        right: 0;
        top: 5.5rem;

        @include respond(tab-port) {
          top: 3.5rem;
        }
        div {
          cursor: pointer;
          height: 34px;
          width: 34px;

          @include respond(tab-port) {
            height: 24px;
            width: 24px;
          }
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      .newsBack {
        position: relative;
        height: auto;
        width: 20%;
        display: flex;
        flex-direction: row;
        background-color: transparent;
        // border: 1px solid grey;

        @include respond(tab-port) {
          width: 50%;
        }
        margin-left: 5%;
        &:hover {
          cursor: pointer;
        }

        img {
          height: 20px;
          // width: 14px;
          margin-top: auto;
          margin-bottom: auto;
        }
        div {
          font-weight: 500;
          font-size: 1.8em;
          margin-left: 4%;
          color: $color-marine;

          word-wrap: break-word;
        }
      }
      .content-div {
        position: relative;
        z-index: 2;
        padding: 0 10rem;
        margin: 0 auto;

        @include respond(tab-port) {
          padding: 0 1rem;
        }
        .content {
          padding: 5rem 5px;
          p {
            font-size: 1.8rem;
          }

          p strong {
            font-size: 38px;
            color: $color-dirty-blue;
          }
        }
        .article-title {
          padding-top: 7rem;
          display: table;
          margin: 0 auto;
          font-size: 3.2rem;
          font-weight: bold;
          text-align: center;

          color: $color-marine;

          @include respond(tab-port) {
            padding-top: 5rem;
          }
        }
        .article-secondary {
          display: table;
          margin: 0 auto;
          font-size: 2.5rem;
          color: #a2a2a3;
        }

        .image-div {
          position: relative;
          margin-top: 25px;
          box-shadow: 0 24px 48px 0 rgba(121, 119, 120, 0.24);
          img {
            max-width: 100%;
            max-height: 80vh;
            width: 100%;
          }
        }
        .intro-div {
          margin-top: 72px;
          color: #797778;
          font-size: 2.4rem;
          p {
            margin-bottom: 30px;
          }
        }
        .first-tip {
          display: flex;
          flex-direction: row;
          margin-top: 70px;
          .first-image {
            width: 50%;
            height: 588px;

            img {
              max-width: 100%;
              max-height: 100%;
              box-shadow: 0 24px 48px 0 rgba(121, 119, 120, 0.24);
            }
          }
          .first-main {
            width: 50%;
            .first-title {
              font-size: 4rem;
              font-weight: 600;
              color: $color-ugly-blue;
              padding-top: 0px;
            }
            .first-content {
              font-size: 2.4rem;
              color: #797778;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
            }
          }
        }
        .second-main {
          margin-top: 64px;
          .second-title {
            font-size: 4rem;
            font-weight: 600;
            color: $color-ugly-blue;
          }
          .second-content {
            font-size: 2.4rem;
            color: #797778;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
          }
        }
        .third-tip {
          margin-top: 64px;
          .third-title {
            font-size: 4rem;
            font-weight: 600;
            color: $color-ugly-blue;
          }
          .third-content {
            font-size: 2.4rem;
            color: #797778;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
          }
          .third-collection {
            display: flex;
            flex-direction: row;
            margin-top: 40px;
            div {
              width: 50%;
              img {
                box-shadow: 0 24px 48px 0 rgba(121, 119, 120, 0.24);
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
        }
        .fourth-tip {
          margin-top: 85px;
          .fourth-title {
            font-size: 4rem;
            font-weight: 600;
            color: $color-ugly-blue;
          }
          .fourth-content {
            font-size: 2.4rem;
            color: #797778;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
          }
          .fourth-quote {
            position: relative;
            display: table;
            margin: 0 auto;
            margin-top: 85px;
            .quote {
              max-width: 932px;
              font-size: 4rem;
              color: $color-marine;
              letter-spacing: 0.72px;
              font-weight: 500;
            }
            .quote-author {
              font-size: 3.2rem;
              letter-spacing: 1.02px;
              color: $color-dirty-blue;
              text-align: center;
              margin-top: 24px;
            }
            .quote-left {
              position: absolute;
              height: 100px;
              width: 117px;
              top: -10px;
              left: -80px;
              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
            .quote-right {
              position: absolute;
              height: 100px;
              width: 117px;
              bottom: 15px;
              right: -30px;
              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
        }
        .fifth-tip {
          margin-top: 85px;
          .fifth-title {
            font-size: 4rem;
            font-weight: 600;
            color: $color-ugly-blue;
          }
          .fifth-content {
            max-height: 520px;
            display: flex;
            flex-direction: row;
            .content-one {
              margin-right: 1%;
              width: 50%;
              font-size: 2.4rem;
              color: #797778;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
            }
            .content-image {
              width: 50%;
              margin-left: 1%;
              img {
                max-width: 100%;
                max-height: 100%;
                box-shadow: 0 24px 48px 0 rgba(121, 119, 120, 0.24);
              }
            }
          }
          .content-two {
            margin-top: 48px;
            margin-right: 1%;
            font-size: 2.4rem;
            color: #797778;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
          }
        }
        .sixth-tip {
          position: relative;
          margin-bottom: 410px;
          .sixth-title {
            font-size: 4rem;
            font-weight: 600;
            color: $color-ugly-blue;
          }
          .sixth-content {
            margin-top: 40px;
            font-size: 2.4rem;
            color: #797778;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-bottom: 40px;
          }
          .sixth-image {
            position: absolute;
            height: 720px;
            bottom: -750px;
            z-index: 9999;

            img {
              max-width: 100%;
              max-height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
    .second-div {
      margin-top: -5rem;
      border: none;
      position: relative;
      z-index: 1;
      height: auto;
      width: 100%;
      overflow: hidden;
      background-color: $color-marine;

      @include overlay-mesh-grid() {
        background-position: bottom;
      }
      .control-div {
        margin-left: 15rem;
        margin-right: 15rem;

        @include respond(tab-port) {
          margin-left: 5rem;
          margin-right: 5rem;
        }
        .gradient-line-horizontal {
          position: relative;
          margin-top: 10rem;
          @include respond(tab-port) {
            margin-top: 5rem;
          }
          z-index: 4;
          // top: 50%;
          width: 100%;
          height: 1px;
          background-size: 100% 1px;
          background-image: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0%,
            $color-white 50%,
            rgba(0, 0, 0, 0) 100%
          );
        }
        .buttons-div {
          @include respond(tab-port) {
            justify-content: center;
            margin-top: 5rem;
            margin-bottom: 7rem;
          }
          position: relative;
          display: flex;
          flex-direction: row;
          position: relative;
          margin-top: 10rem;
          width: 100%;
          padding: 0 15rem;
          margin-bottom: 5rem;
          .up-button {
            border-color: transparent;
            position: fixed;
            height: 40px;
            width: 40px;
            z-index: 1000;
            bottom: 10rem;
            outline: none;
            right: 5rem;

            border-radius: 50%;
            img {
              width: 18px;
              height: 10px;
              margin-left: 4%;
            }
            a {
              position: absolute;
              width: 100%;
              height: 100%;
              right: 0;
              bottom: 0;
            }
          }
          @include respond(tab-port) {
            max-width: 400px;
          }
          .prev-a {
            @include respond(tab-port) {
              min-width: 160px;
              height: 42px;
            }
            position: relative;
            height: 80px;
            width: 380px;
            display: flex;
            flex-direction: row;
            background-color: white;
            &:hover {
              .theimage {
                transform: rotateZ(180deg);
                background-image: url("../../img/singleProject/back-white.png");
              }
            }

            .theimage {
              position: absolute;
              height: 22px;
              width: 12px;
              border-color: transparent;
              background-size: contain;
              background-repeat: no-repeat;
              top: 35%;
              background-image: url("../../img/singleProject/back-blue.png");
              left: 10%;

              @include respond(tab-port) {
                height: 15px;
                width: 10px;
                left: 5%;
              }
            }
            div {
              position: absolute;
              top: 30%;
              font-size: 1.8rem;
              right: 10%;
              // color: $color-dirty-blue;
              letter-spacing: 1px;
              text-transform: uppercase;
              @include respond(tab-port) {
                font-size: 11px;
              }
            }
          }
          .next-a {
            @include respond(tab-port) {
              min-width: 160px;
              height: 42px;
            }
            position: relative;
            height: 80px;
            width: 380px;
            display: flex;
            flex-direction: row;
            background-color: $color-dirty-blue;
            margin-left: 5%;
            transition: all 1s;
            &:hover {
              .theimage {
                transform: rotateZ(180deg);
                background-image: url("../../img/singleProject/back-blue.png");
              }
            }

            .theimage {
              position: absolute;
              height: 22px;
              width: 12px;
              background-size: contain;
              background-repeat: no-repeat;
              top: 35%;
              left: 85%;
              background-image: url("../../img/singleProject/back-white.png");

              @include respond(tab-port) {
                height: 15px;
                width: 10px;
                left: 90%;
              }
            }
            div {
              position: absolute;
              top: 30%;
              font-size: 1.8rem;
              left: 10%;
              // color: $color-dirty-blue;
              letter-spacing: 1px;
              text-transform: uppercase;
              @include respond(tab-port) {
                font-size: 1.2rem;
                top: 28%;
                left: 5%;
              }
            }
          }
        }
      }
    }
  }
}

.test {
}
