.news-main {
  overflow: auto;
  .filter-background {
    @include respond(phone) {
      display: none;
      z-index: 2000;
      position: fixed;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      bottom: 0;
    }
  }
  .filter-modal {
    overflow: auto;
    height: auto;
    @include respond(phone) {
      display: none;
      height: 100%;
      width: 100%;
      background-color: $color-white;
      z-index: 2000;
      position: fixed;
    }
    .filter-content {
      margin: 3rem 24px;
      height: 100%;
      .filter-intro {
        position: relative;
        display: flex;
        flex-direction: row;

        .filter-title {
          font-size: 24px;
          color: $color-marine;
          font-weight: bold;

          span {
            padding-left: 20px;
            height: 1px;
          }
        }
      }
      .filter-options {
        .dropdown:active {
        }
        .dropdown {
          .choice-holder {
            display: none;
            .choice {
              margin-top: 24px;
              display: flex;
              flex-direction: column;
              span {
                font-size: 14px;
                color: $color-dirty-blue;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
              }
              input {
                background-color: white;
                width: 100%;
                height: 42px;
                border: 1.5px solid $color-dirty-blue;
                color: $color-dirty-blue;
                padding-left: 11px;
              }
            }
          }
          .selected {
            div {
              transform: rotateX(180deg);
            }
          }

          .select-date {
            position: relative;
            height: 72px;
            border-bottom: 1px solid rgba(9, 49, 72, 0.16);
            font-size: 18px;
            color: $color-dark-grey;
            div {
              position: absolute;
              height: 12px;
              width: 20px;
              background-size: cover;
              top: 40%;
              right: 25px;
              background-image: url("../../img/news/back.png");
            }

            label {
              position: absolute;
              left: 0;
              top: 23.5px;
            }
          }
        }
        position: relative;
        margin-top: 49px;
        display: flex;
        flex-direction: column;
        .option {
          position: relative;
          height: 72px;

          input {
            background-color: white;
            opacity: 0;
            height: 100%;
            width: 100%;
            &:hover {
              cursor: pointer;
            }
          }
          label {
            position: absolute;
            left: 0;
            top: 23.5px;
          }
          img {
            display: none;
            position: absolute;
            height: 14px;
            top: 50%;
            right: 20px;
          }
          input[type="checkbox"]:checked + label {
            color: $color-dirty-blue;
            font-style: normal;
          }
          input[type="checkbox"]:checked + label + img {
            display: block;
          }

          width: 100%;
          // padding-bottom: 23.5px;
          // padding-top: 23.5px;
          border-bottom: 1px solid rgba(9, 49, 72, 0.16);
          font-size: 18px;
          color: $color-dark-grey;
          font-weight: bold;
        }
      }
      .buttons-div {
        position: relative;
        margin-top: 13rem;

        @include respond(tab-port) {
          margin-top: 8rem;
        }
        .button--isi {
          margin: 0 auto;
          width: 40%;
        }
        a {
          text-decoration: underline;
          cursor: pointer;
          top: 24px;
          position: relative;
          left: 46%;
          color: black;
          font-size: 12px;
        }
      }
    }
  }

  .news-header {
    position: relative;
    background-size: contain;
    background-color: white;

    .news-container {
      position: relative;
      margin-left: 5%;
      margin-right: 5%;

      .news-title {
        @include respond(phone) {
          font-size: 3.1rem;
          padding-top: 148px;
        }
        position: relative;
        display: table;
        margin: 0 auto;
        padding-top: 40vh;
        font-size: 6.2rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $color-marine;
      }

      .div-posts {
        display: flex;
        flex-direction: column;
        padding-left: 2%;
        padding-top: 10px;

        .recent-div {
          .recent-title {
            font-weight: 600;
            font-size: 2rem;
            color: $color-ugly-blue;
          }

          .posts-container {
            font-size: 2rem;
            font-weight: 500;
            color: #797778;

            .single-post {
              margin-top: 1.5rem;

              &:hover {
                color: $color-marine;
                cursor: pointer;
              }
            }
          }
        }

        .sort-div {
          margin-top: 192px;

          .sort-title {
            font-size: 2rem;
            color: $color-ugly-blue;
            font-weight: 600;
            margin-bottom: 22px;
          }

          .checkBoxContainer input {
            display: none;
          }

          .checkBoxContainer {
            position: relative;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-bottom: 24px;
          }

          .checkBoxContainer .checkmark {
            width: 20px;
            height: 20px;
            border: 1px solid #797778;

            background-color: white;
            position: relative;
            // margin-top: 6px;
          }

          .checkBoxContainer .check-title {
            margin-left: 16px;
            font-size: 2rem;
            font-weight: 500;
            color: #797778;
          }
        }
      }

      .news-div {
        @include respond(phone) {
          flex-direction: column;
          margin-top: 35px;
        }
        display: flex;
        flex-direction: row;

        margin-top: 9rem;

        .div-articles {
          @include respond(phone) {
            width: 100%;
            border-right: none;
          }
          width: 65%;
          border-right: 1px solid #a2a2a3a5;
          margin-bottom: 10rem;
          .single-article {
            @include respond(phone) {
              margin-bottom: 65px;
            }
            display: none;
            margin-bottom: 95px;

            .article-title {
              @include respond(phone) {
                font-size: 17px;
              }
              font-size: 2.8rem;
              font-weight: 600;
              color: $color-marine;
              max-width: 80%;
            }

            .article-secondary {
              @include respond(phone) {
                font-size: 13px;
              }
              font-size: 2rem;
              color: #a2a2a3;
            }

            .article-image {
              margin-top: 32px;
              position: relative;
              width: 90%;
              @include respond(phone) {
                width: 100%;
                margin-top: 15px;
              }

              img {
                width: 100%;
                max-height: 100%;
              }

              .featured-post {
                @include respond(phone) {
                  top: 3.5rem;
                  width: auto;
                  font-size: 12px;
                  padding: 10px 5px;
                }
                position: absolute;
                top: 4.5rem;
                width: 25%;
                height: 4.6rem;
                background-color: rgba(3, 21, 31, 0.48);
                text-align: center;
                padding: 10px 0;
                font-size: 1.6rem;
                color: white;
                font-weight: bold;
              }

              .article-date {
                position: absolute;
                width: 10%;
                height: 75px;
                background-color: white;
                top: 4.5rem;
                right: 5%;
                color: $color-dirty-blue;
                @include respond(tab-port) {
                  width: 15%;
                  top: 3rem;
                  height: unset;
                }

                span {
                  display: table;
                  margin: 0 auto;
                  padding-top: 10px;
                  font-size: 1.2rem;
                  font-weight: 600;
                  border-bottom: 2px solid $color-dirty-blue;
                }

                label {
                  display: table;
                  margin: 0 auto;
                  font-size: 2.4rem;
                  font-weight: bold;
                }
              }
            }

            .article-description {
              max-width: 90%;
              font-size: 2rem;
              color: #797778;
              margin-top: 40px;
              @include respond(tab-port) {
                margin-top: 20px;
              }

              & :first-child {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                -webkit-box-orient: vertical;
              }

              & :not(:first-child) {
                display: none;
              }
            }

            .button--isi {
              @include respond(phone) {
                padding: 1rem;
                width: 40%;
                font-size: 12px;
                margin-top: 30px;
              }
              margin-top: 40px;
            }
          }

          .button-load {
            position: relative;
            @include respond(phone) {
              margin-top: 0;
              width: 45%;
              height: 42px;
              border: 1px solid $color-dirty-blue;
              font-size: 11px;
            }
            display: table;
            margin: 0 auto;
            width: 50%;
            margin-top: 0rem;
            // margin-bottom: 10rem;
            border: 2px solid $color-dirty-blue;
          }
        }

        .div-posts {
          @include respond(phone) {
            display: none;
          }
          width: 35%;
          // border-left: 1px solid #a2a2a3a5;
        }
      }
    }
  }
  .filter-button {
    display: none;

    @include respond(phone) {
      display: block;
    }
  }
}
